import React, { useEffect, useRef, useState } from 'react';
import { getDeviceType } from '../../Utils/deviceUtils';
import herocontainer from "../../Components/Assets/herocontainer.png";
import hero from "../../Components/Assets/hero.png";
import arrow from "../../Components/Assets/arrow.svg";
import styled from 'styled-components';
import { ReactComponent as SearchIconSVG } from '../../Components/Assets/Search.svg';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { useDevice } from '../../Components/Context/deviceContext';
import '../../Components/Navbar/Navbar.css'
import profile from '../../Components/Assets/profile.svg';
import LazyLoad from 'react-lazyload';
import atlocation from '../../Components/Assets/atlocation.svg';
import Footer from '../../Components/fotter';

// Styled components
const Container = styled.div`
  min-height: 100vh;
`;

const InnerContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    width: 88%;
  }
`;

const HeroSection = styled.div`
  height: 30vh;
  background-color: #FFE1AC;
  margin-top: 10vh;
  border-radius: 5vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 501px) and (max-width: 1200px) {
      margin-top:7vh;
      height: 26vh;
      border-radius: 10vw;
      justify-content: start;
    }
      @media (max-width: 500px) {
       margin-top:5vh;
         height: 16vh;
         justify-content: start;
    }
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

const BackgroundImage = styled.img`
  height: 100%;
   border-radius: 4vw;
  width: 100%;
  
  object-fit: cover;
`;

const OverlayWrapper = styled.div`
  position: absolute;
  height: 34vh;
  top: -4.3vh;
  left: 70vw;
  z-index: 3;
  @media (min-width: 501px) and (max-width: 1200px) {
  left: 54vw;
         top: -4.3vh;
       height: 30vh;
    }
      @media (max-width: 500px) {
         left: 54vw;
         top: -2.3vh;
         height: 18vh;
    }
`;

const OverlayImage = styled.img`
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 4;
  text-align: center;
  @media (max-width: 1200px) {
      margin-left: 5vw;
      text-align: start;
      align-items: start;
    }
`;

const Heading = styled.div`
  font-size: 2vw;
  font-weight: 400;
  @media (min-width: 768px) and (max-width: 1200px) {
      font-size: 3.8vw;
      letter-spacing:  0.5vw;
    }
      @media (max-width: 767px) {
        font-size:3.4vw;
        letter-spacing:  0.5vw;
    }
`;

const Heading1 = styled.div`
  font-size: 2vw;
  font-weight: 400;
  @media (min-width: 501px) and (max-width: 1200px) {
      font-size: 3.8vw;
      font-wieght: 600;
      letter-spacing:  0.5vw;
    }
      @media (max-width: 500px) {
        font-size:4.7vw;
        font-wieght: 700;
        letter-spacing:  0.5vw;
    }
`;

const Button = styled.button`
  padding: 0.7vw 1.3vw; 
  margin-top: 2vh;
  background-color: #FABA49;
  color: #000;
  font-size: 1.1vw;
  border: none;
  border-radius: 4vw;
  cursor: pointer;
  overflow: hidden; 
  display: flex; 
  align-items: center; 
  justify-content: center;
  @media (min-width: 501px) and (max-width: 1200px) {
       margin-top: 0.8vh;
  padding: 0.8vw 2.2vw; 
  font-size: 1.5vw;
  letter-spacing: 0.5vw;
    }
 @media (max-width: 500px) {
  margin-top: 0.8vh;
  padding: 1.2vw 2.2vw; 
  font-size: 2.5vw;
  letter-spacing: 0.5vw; /* Corrected "letter-spaing" to "letter-spacing" */
}
`;

const SearchContainer = styled.div`
    position: relative;
    width: 18vw;
    margin-right: 6.5vw;
    margin-top: 2vh;
    @media (min-width: 501px) and (max-width: 1200px) {
       width: 83%;
       margin-right: 0;
    }
      @media (max-width: 500px) {
       width: 83%;
        margin-right: 0;
    }
`;

const SearchInput = styled.input`
    padding: 0.7vw 2.5vw; /* Adjusted padding to make space for the icon */
    width: 100%;
    border-radius: 4vw;
    font-size: 1.1vw;
    border: 1px solid #FABA49;
    transition: border-color 0.3s;
    &:focus {
        background-color: #FFF7D4;
        border: 1px solid #FABA49;
        outline: none;
    }
        @media (min-width: 501px) and (max-width: 1200px) {
     padding: 2vw 7vw;
     font-size: 3.8vw;
    }
      @media (max-width: 500px) {
      padding: 2vw 7vw;
         font-size: 3.8vw;
    }
`;

const SearchIcon = styled(SearchIconSVG)`
    position: absolute;
    top: 50%;
    left: 0.7vw; /* Adjust as needed */
    transform: translateY(-50%);
    width: 1.2vw; /* Adjust size as needed */
    height: 1.2vw; /* Adjust size as needed */
    color: #FABA49; /* You can adjust the color or apply a fill if needed */
    @media (min-width: 768px) and (max-width: 1200px) {
       width: 3vw;
       height: 3vw; 
       left: 1.7vw; 
    }
      @media (max-width: 767px) {
       width: 3vw;
       height: 3vw; 
       left: 1.7vw; 
    }
`;



// Styled components
const GridContainer = styled.div`
  display: grid;
  height: auto;
  gap: 3vh;
  grid-template-columns: repeat(2, 1fr);
  @media (min-width: 501px) and (max-width: 1200px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 2vh;
    }
      @media (max-width: 500px) {
        grid-template-columns: repeat(1, 1fr);
         gap: 2vh;
    }
`;

const GridItem = styled.div`
  border: 1.5px solid #FABA49;
  cursor: pointer;
  border-radius: 3vw;
  display: flex;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (min-width: 501px) and (max-width: 1200px) {
    width: 99%;
    border-radius: 4vw;
  }

  @media (max-width: 500px) {
    width: 99%;
    border-radius: 7vw;
  }
`;


const StyledDiv4 = styled.div`
  margin-left: 5%;
  margin-top: 2.5vh;
  font-size: 6vw;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #FABA49;
  }
`;

const StyledButton1 = styled.button`
  cursor: pointer;
  padding: 0rem 1.2rem;
  height:32px;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  letter-spacing: 0.05rem;
  font-weight: 400;
  font-size: 16px;
  border-radius: 400px;
  border: 2px solid #FABA49;
  overflow: hidden;
  background: #FABA49;
  color: black;
  transition: color 0.4s;
  position: relative;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    content: '';
  }

  &::before {
    background: #FADAA1;
    
    width: 120%;
    left: -10%;
    transform: skew(30deg);
    transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
  }

  &:hover::before {
    transform: translate3d(100%, 0, 0);
  }

  &:hover span {
    color: black;
  }

  span {
    position: relative;
    z-index: 10;
    transition: color 0.4s;
  }
`;

const HideContainer = styled.div`
  @media (max-width: 1200px) {
    display: none;
  }
`;


const Home = () => {
    const deviceType = getDeviceType();
    const [isActive, setIsActive] = useState(false);
    const { phoneNumber } = useDevice();
    const [locationAllowed, setLocationAllowed] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const hasRequestedPermission = useRef(false);
    const { carShops, distances } = useDevice();
    const [originalDistances, setOriginalDistances] = useState([]);
    const aboutRef = useRef(null);

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth" });
      };

    useEffect(() => {
        // Check if location is already stored
        const latitude = localStorage.getItem('latitude');
        const longitude = localStorage.getItem('longitude');

        if (latitude && longitude) {
            setLocationAllowed(true);
            console.log('Location already saved.');
        } else if (!hasRequestedPermission.current) {
            requestLocationPermission();
        }
    }, []);

    useEffect(() => {
        // Retrieve merchantTransactionId from localStorage
        const merchantTransactionId = localStorage.getItem('merchantTransactionId');
    
        if (merchantTransactionId) {
          const checkPaymentStatus = async () => {
            try {
              const paymentStatusResponse = await fetch('https://www.dsctech.in/api/check_payment_status/', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  merchantTransactionId: merchantTransactionId, // Using transactionId from localStorage
                }),
              });
    
              // Handle the response from the API
              if (paymentStatusResponse.ok) {
                localStorage.removeItem('merchantTransactionId');
              } else {
                console.error('Error checking payment status');
              }
            } catch (error) {
              console.error('An error occurred while checking payment status:', error);
            }
          };
    
          checkPaymentStatus();
        }
      }, []);

    useEffect(() => {
        // Set original distances when carShops is first loaded
        if (carShops.length > 0 && originalDistances.length === 0) {
            setOriginalDistances(distances);
        }
    }, [carShops, distances]);

    const requestLocationPermission = () => {
        hasRequestedPermission.current = true;
        const userConfirmed = window.confirm('This application needs access to your location. Do you want to allow it?');

        if (userConfirmed) {
            promptForLocation();
        } else {
            alert('Location access denied. Some features may not work properly.');
        }
    };

    const promptForLocation = async () => {
        console.log('promptForLocation called'); // For debugging
        if (navigator.geolocation) {
            try {
                const position = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject, {
                        enableHighAccuracy: true,
                        timeout: 10000,
                        maximumAge: 0,
                    });
                });

                const { latitude, longitude } = position.coords;
                localStorage.setItem('latitude', latitude);
                localStorage.setItem('longitude', longitude);
                setLocationAllowed(true);
                window.location.reload();
                console.log('Location saved:', latitude, longitude);
            } catch (error) {
                console.error('Error getting location:', error);
                alert('Failed to get location. Please enable location services and try again.');
            }
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredCarShops = carShops.filter((shop) =>
        shop.fields.shop_name.toLowerCase().includes(searchTerm) ||
        shop.fields.owner_name.toLowerCase().includes(searchTerm) ||
        shop.fields.address.toLowerCase().includes(searchTerm)
    );

    const navigate = useNavigate();

    const handleClick = (id, distance) => {
        const phoneNumber = localStorage.getItem('phoneNumber'); // Retrieve phone number from local storage
    
        if (!phoneNumber) {
            alert('Please log in to continue.'); // Show alert if phone number is not found
            return; // Exit the function if phone number is not found
        }
    
        console.log(`Shop ID: ${id}, Distance: ${distance} kms`);
        navigate(`/carwash/${id}?distance=${distance}`);
    };
    
    

    const handleprofileClick = () => {
        navigate(`/profile`);
    };

    const [hiddenClass, setHiddenClass] = useState('');
    const [hiddenClassmobile, setHiddenClassmobile] = useState('');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1200) {
                setHiddenClass('hidden');
            } else {
                setHiddenClass('');
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleResizemobile = () => {
            if (window.innerWidth < 500) {
                setHiddenClassmobile('hidden');
            } else {
                setHiddenClassmobile('');
            }
        };

        window.addEventListener('resize', handleResizemobile);
        handleResizemobile(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResizemobile);
        };
    }, []);

    const [name, setName] = useState('');

    useEffect(() => {
        // Retrieve the name from localStorage
        const storedName = localStorage.getItem('name');
        if (storedName) {
            setName(storedName);
        }
    }, []);

    const navLinks = [
        { name: 'Home', path: '/' },
        { name: 'About Us', path: '/aboutus' },
        { name: 'My Bookings', path: '/orders' },
        { name: 'Share App', path: '#' }, // Placeholder path for the Share App button
    ];

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Check out this app!',
                text: 'I thought you might like this app. Check it out!',
                url: window.location.href,
            })
                .then(() => console.log('Thanks for sharing!'))
                .catch((error) => console.log('Error sharing:', error));
        } else if (window.flutter_inappwebview) {
            // Notify Flutter to handle sharing
            window.flutter_inappwebview.callHandler('shareApp');
        } else {
            alert('Web Share API not supported. Copy the URL manually: ' + window.location.href);
        }
    };


    const handleNavigation = (path) => {
        navigate(path);
    };


    const handleLogout = () => {
        // Remove phone number from localStorage
        setIsActive(false);
        localStorage.removeItem('phoneNumber');
        navigate('/login');
    };


    return (
        <div>
            <Container>
            <InnerContainer>
                {hiddenClass ? (
                    <div >
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8vh' }}>
                            <div
                                onClick={() => setIsActive(!isActive)}
                                className="button"
                                style={{ marginLeft: '0', zIndex: '7' }}
                            >
                                <div className={`burger ${isActive ? 'burgerActive' : ''}`}></div>
                            </div>
                            <div className={`dialog ${isActive ? 'dialogActive' : ''}`} style={{ zIndex: '6' }}>
                                <div style={{ marginTop: '20vh' }}>
                                    {navLinks.map((link, index) => (
                                        link.name === 'Share App' ? (
                                            <StyledDiv4
                                                key={index}
                                                onClick={handleShare}
                                            >
                                                {link.name}
                                            </StyledDiv4>
                                        ) : (
                                            <StyledDiv4
                                                key={index}
                                                onClick={() => handleNavigation(link.path)}
                                            >
                                                {link.name}
                                            </StyledDiv4>
                                        )
                                    ))}
                                </div>
                                <StyledButton1 style={{ marginRight: "5%", width: '90%', height: '6%', marginLeft: '5%', marginTop: '3.5vh' }} onClick={handleLogout}>
                                    <span>Logout</span>
                                </StyledButton1>
                            </div>
                            <div style={{ height: '6vh', width: "6vh", border: '1px solid #FABA49', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={handleprofileClick}>
                                <img src={profile} height='60%' width='auto' />
                            </div>

                        </div>
                        <div style={{ fontSize: hiddenClassmobile ? '3.8' : '2.6vw', letterSpacing: '0.5vw', marginTop: '2vh', color: 'rgba(0, 0, 0, 0.6)' }}>
                            Hello {name},
                        </div>
                        <div style={{ fontSize: hiddenClassmobile ? '3.8' : '2.6vw', letterSpacing: "0.5vw" }}>What service do you need?</div>
                        <HeroSection>
                            <BackgroundWrapper>
                                <BackgroundImage src={herocontainer} alt="Background" />
                            </BackgroundWrapper>
                            <OverlayWrapper>
                                <OverlayImage src={hero} alt="Overlay" />
                            </OverlayWrapper>
                            <Content>
                                <div style={{ fontSize: hiddenClassmobile ? '3.8' : '2.6vw', letterSpacing: "0.2vw" }}>
                                    Premium Car Wash at<br />Your Doorstep
                                </div>
                                <Button onClick={() => scrollToSection(aboutRef)}>Book Now</Button>
                            </Content>
                        </HeroSection>
                        <div style={{ marginTop: '2.5vh', display: 'flex', justifyContent: "space-between", alignItems: 'center' }} ref={aboutRef}>
                            <Heading1>
                                Service provider
                            </Heading1>
                            {/* <Button style={{ fontSize: '3vw', padding: '1.6vw 4vw', backgroundColor: '#FADAA1' }}>
                                SORT BY
                            </Button> */}
                        </div>
                        <SearchContainer>
                            <SearchIcon />
                            <SearchInput
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </SearchContainer>
                        <div style={{ height: '3vh' }} />
                        <div>
                            <GridContainer style={{ marginBottom: '5vh' }}>
                                {filteredCarShops.length > 0 ? (
                                    filteredCarShops.map((item, index) => {
                                        return (
                                            <GridItem key={item.pk} onClick={() => handleClick(item.pk, originalDistances[filteredCarShops.indexOf(item)])}>
                                                <div style={{ height: hiddenClassmobile ? '15vh' : '20vh', width: hiddenClassmobile ? '15vh' : '20vh', borderRadius: hiddenClassmobile ? '5vw' : '3vw', backgroundColor: 'transparent', overflow: 'hidden', margin: hiddenClassmobile ? '2vh' : '4vh', display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                                                    <img src={`https://www.dsctech.in/media/${item.fields.upload_carshop_image}`} alt='' style={{ height: '100%', width: '100%' }} />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: 'column', justifyContent: "start", marginTop: 'auto', marginBottom: 'auto' }}>
                                                    <div style={{ fontSize: hiddenClassmobile ? "4.6vw" : '3.4vw' }}>{item.fields.shop_name}</div>
                                                    <div style={{ color: 'rgb(0, 0, 0, 0.6)', fontSize: hiddenClassmobile ? '3vw' : '2vw' }}>
                                                        {originalDistances[filteredCarShops.indexOf(item)]} kms away
                                                    </div>
                                                    <div style={{ color: 'rgb(0, 0, 0, 0.6)', fontSize: hiddenClassmobile ? '3vw' : '2vw' }}>{item.fields.address}</div>
                                                </div>
                                                <div style={{ marginLeft: "auto", marginTop: 'auto', marginBottom: 'auto', marginRight: "3vh" }}>
                                                    <div style={{ height: hiddenClassmobile ? '5vh' : '7vh', width: hiddenClassmobile ? '5vh' : '7vh', borderRadius: '50%', backgroundColor: '#FFE9C2', alignItems: "center", justifyContent: "center", display: "flex" }}>
                                                        <img src={arrow} alt='arrow' height='50%' style={{ marginLeft: '0.5vh' }} />
                                                    </div>
                                                </div>
                                            </GridItem>
                                        );
                                    })
                                ) : (
                                    <p>No car shops available.</p>
                                )}
                            </GridContainer>
                        </div>
                    </div>

                ) :
                    (
                        <HideContainer>
                            <HeroSection>
                                <BackgroundWrapper>
                                    <BackgroundImage src={herocontainer} alt="Background" />
                                </BackgroundWrapper>
                                <OverlayWrapper>
                                    <OverlayImage src={hero} alt="Overlay" />
                                </OverlayWrapper>
                                <Content>
                                    <Heading>
                                        Premium Car Wash at<br />Your Doorstep
                                    </Heading>
                                    <Button onClick={() => scrollToSection(aboutRef)}>Book Now</Button>
                                </Content>
                            </HeroSection>
                            <div style={{ height: '3vh' }} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} ref={aboutRef}>
                                <Heading>
                                    Service provider
                                </Heading>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <SearchContainer>
                                        <SearchIcon />
                                        <SearchInput
                                            type="text"
                                            placeholder="Search..."
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                        />
                                    </SearchContainer>
                                    {/* <Button style={{ backgroundColor: "#FFE9C2", border: '1px solid #FABA49' }}>
                                        Sort By
                                    </Button> */}
                                </div>
                            </div>
                            <div style={{ height: '3vh' }} />
                            <div>

                                {filteredCarShops.length > 0 ? (
                                    <GridContainer >
                                        {filteredCarShops.map((item, index) => (
                                            <GridItem key={item.pk} onClick={() => handleClick(item.pk, originalDistances[filteredCarShops.indexOf(item)])}>
                                                <div style={{ height: '21vh', width: '21vh', borderRadius: '2vw', backgroundColor: 'transparent', overflow: 'hidden', margin: '3vh', border: '1.5px solid #FABA49', display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                                                    <img src={`https://www.dsctech.in/media/${item.fields.upload_carshop_image}`} alt='' style={{ height: '17vh', width: '17vh', borderRadius: '1.4vw' }} />
                                                </div>
                                                <div style={{ display: "flex", flexDirection: 'column', justifyContent: "start", marginTop: 'auto', marginBottom: 'auto' }}>
                                                    <div style={{ fontSize: "1.5vw" }}>{item.fields.shop_name}</div>
                                                    <div style={{ color: 'rgb(0, 0, 0, 0.6)' }}>
                                                        {originalDistances[filteredCarShops.indexOf(item)]} kms away
                                                    </div>
                                                    <div style={{ color: 'rgb(0, 0, 0, 0.6)' }}>{item.fields.address}</div>
                                                </div>
                                                <div style={{ marginLeft: "auto", marginTop: 'auto', marginBottom: 'auto', marginRight: "3vh" }}>
                                                    <div style={{ height: '7vh', width: '7vh', borderRadius: '50%', backgroundColor: '#FFE9C2', alignItems: "center", justifyContent: "center", display: "flex" }}>
                                                        <img src={arrow} alt='arrow' height='40%' style={{ marginLeft: '0.5vh' }} />
                                                    </div>
                                                </div>
                                            </GridItem>
                                        ))}
                                    </GridContainer>
                                ) : (
                                    <div style={{ width: '100%', marginTop: '6vh', display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center' }}>
                                        <div >We're sorry, but there are no services in your location right now. We're constantly expanding, so please stay tuned!</div>
                                        <img  src={atlocation} height='200' style={{marginTop: '3vh'}}/>
                                    </div>

                                )}

                            </div>
                            <div style={{ height: '6vh' }} />
                        </HideContainer>
                    )}

            </InnerContainer>
        </Container>
        {hiddenClass ? (
            <></>
        ): (
            <Footer/>
        )}
        </div>
    );
};

export default Home;
