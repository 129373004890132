import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDevice } from '../Components/Context/deviceContext'
import { useNavigate } from 'react-router-dom'
import arrow from '../Components/Assets/arrow.svg'
import pleaselogin from '../Components/Assets/pleaselogin.svg'
import noorders from '../Components/Assets/noorders.svg'
import { LoadingSpinner } from '../Components/LoadingSpinner '
import axios from 'axios'

const GridContainer = styled.div`
  display: grid;
  min-height: 22vh;
  margin-top: 5vh;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
  grid-template-columns: repeat(2, 1fr);
  gap: 3%;
  @media (min-width: 768px) and (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
    margin-left: 0%;
    margin-right: 0%;
    margin-top: 3vh;
    gap: 1%;
    margin-bottom: 5vh;
  }
  @media (max-width: 767px) {
    margin-left: 0%;
    margin-right: 0%;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 3h;
    gap: 1%;
    margin-bottom: 5vh;
  }
`

const GridItem = styled.div`
  border: 2px solid #faba49;
  margin-bottom: 2vh;
  border-radius: 3vw;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  @media (min-width: 768px) and (max-width: 1200px) {
    border-radius: 7vw;
    margin-bottom: 0vh;
  }
  @media (max-width: 767px) {
    margin-bottom: 0vh;
    border-radius: 7vw;
  }
`

const ShopDetails = styled.div`
  font-size: 1.8vw;
  font-weight: 400;
  margin-left: 5%;
  margin-top: 3vh;
  letter-spacing: 0.1vw;
`

const DistanceText = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 1vw;
`

const HideContainer = styled.div`
  @media (max-width: 1200px) {
    display: none;
  }
`

const formatDate = (date) => {
  const options = { day: '2-digit', month: 'short' }
  const formattedDate = new Date(date).toLocaleDateString('en-US', options)
  const [month, day] = formattedDate.split(' ')
  return `${month.toUpperCase()} ${day.toUpperCase()}`
}

const PreviousOrders = () => {
  const navigate = useNavigate()
  const { phoneNumber } = useDevice()
  const [orders, setOrders] = useState([])
  const [shopDetailsMap, setShopDetailsMap] = useState(new Map())
  const [originalDistances, setOriginalDistances] = useState([])
  const [loading, setLoading] = useState(false)
  const [hiddenClass, setHiddenClass] = useState('')
  const [hiddenClassmobile, setHiddenClassmobile] = useState('')
  const [isPhonePresent, setIsPhonePresent] = useState(false)
  const [error, setError] = useState(null)

  const handleCancelOrder = async (shopId) => {
    // Ask for confirmation before cancelling the booking
    const isConfirmed = window.confirm(
      'Are you sure you want to cancel the booking?',
    )
    if (!isConfirmed) {
      return // Exit if user doesn't confirm
    }

    setLoading(true)
    try {
      // Step 1: Cancel the booking
      const response = await fetch(
        `https://www.dsctech.in/api/bookings/${shopId}/`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )

      if (response.ok) {
        console.log('Booking cancelled successfully')

        // Optionally, update the state to remove the cancelled order from the view
        setShopDetailsMap((prevState) => {
          const newState = new Map(prevState)
          newState.delete(shopId) // Remove the cancelled shop from state
          return newState
        })

        // Step 2: Make the refund API call
        const refundResponse = await fetch(
          `https://www.dsctech.in/api/refund-payment/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ BookingID: shopId }),
          },
        )

        if (refundResponse.ok) {
          const refundData = await refundResponse.json()
          console.log('Refund successful:', refundData)

          console.log(refundData.response_data.transactionId)

          window.location.reload()
        } else {
          console.error('Failed to process the refund')
          setError('Failed to process the refund')
        }
      } else {
        console.error('Failed to cancel the booking')
        setError('Failed to cancel the booking')
      }
    } catch (error) {
      console.error('Error:', error)
      setError('An error occurred while cancelling the booking')
    } finally {
      setLoading(false) // Set loading to false after the operation is complete
    }
  }

 


  useEffect(() => {
    // Check if phoneNumber exists in localStorage
    const storedPhoneNumber = localStorage.getItem('phoneNumber')
    if (!storedPhoneNumber) {
      setIsPhonePresent(false)
    } else {
      setIsPhonePresent(true)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setHiddenClass('hidden')
      } else {
        setHiddenClass('')
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handleResizemobile = () => {
      if (window.innerWidth < 500) {
        setHiddenClassmobile('hidden')
      } else {
        setHiddenClassmobile('')
      }
    }

    window.addEventListener('resize', handleResizemobile)
    handleResizemobile() // Initial check

    return () => {
      window.removeEventListener('resize', handleResizemobile)
    }
  }, [])

  useEffect(() => {
    if (phoneNumber) {
      setLoading(true)
      // Construct the URL with phoneNumber
      const url = `https://www.dsctech.in/api/get_previous_orders/${phoneNumber}/`

      // Fetching previous orders
      axios
        .get(url)
        .then((response) => {
          const orders = response.data.orders
          const ordersMap = new Map()

          // Map over the orders and populate the map with order details
          orders.forEach((order) => {
            ordersMap.set(order.id, {
              id: order.id,
              shop_name: order.shop.name,
              upload_carshop_image: order.shop.upload_carshop_image_url,
              booking_status: order.booking_status,
              date_time: order.booking_date,
              service_name: order.service.service_name,
              cost: order.final_amount,
              paymentstatus: order.payment_status,
            })
          })

          setShopDetailsMap(ordersMap)
          setLoading(false)
        })
        .catch((err) => {
          console.error(err) // Add more detailed error logging
          setLoading(false)
        })
    }
  }, [phoneNumber])

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <div>{error}</div>
  }

  if (!isPhonePresent) {
    return (
      <div
        style={{
          width: '100vw',
          height: hiddenClass ? '100vh' : '80vh',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {hiddenClass ? (
          <div>
            <button
              onClick={handleBack} // Call handleBack on button click
              style={{
                position: 'absolute',
                top: '7vh',
                left: '7%',
                marginTop: '10px',
                padding: '15px',
                borderRadius: '30px',
                border: '1px solid #ffffff',
                backgroundColor: '#FADAA1',
                color: 'black',
                cursor: 'pointer',
                marginRight: '10px',
              }}
            >
              Back
            </button>
          </div>
        ) : (
          <div></div>
        )}
        Please login to view your previous orders.
        <img
          src={pleaselogin}
          alt="Please login"
          style={{ marginTop: '5vh' }}
          height="45%"
        />
      </div>
    )
  }
  const handleBack = () => {
    window.history.back() // Example to go back to the previous page
  }

  if (shopDetailsMap.size === 0) {
    return (
      <div
        style={{
          width: '100vw',
          height: hiddenClass ? '100vh' : '80vh',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {hiddenClass ? (
          <div>
            <button
              onClick={handleBack} // Call handleBack on button click
              style={{
                position: 'absolute',
                top: '7vh',
                left: '7%',
                marginTop: '10px',
                padding: '15px',
                borderRadius: '30px',
                border: '1px solid #ffffff',
                backgroundColor: '#FADAA1',
                color: 'black',
                cursor: 'pointer',
                marginRight: '10px',
              }}
            >
              Back
            </button>
          </div>
        ) : (
          <div></div>
        )}
        <div>No previous orders.</div>
        <img
          src={noorders}
          alt="Please login"
          style={{ marginTop: '5vh' }}
          height="40%"
        />
      </div>
    )
  }

  if (loading) {
    return <LoadingSpinner />
  }

  const handleClick = () => {
    navigate(-1) // This goes back to the previous page
  }
  const handleClick1 = (id) => {
    navigate(`/booking/${id}`)
  }


  const createBooking1 = async (id) => {
    setLoading(true) // Start loading
    try {
      // Prepare payment data
      const paymentData = {
        BookingID: parseInt(id, 10),
      }

      // Make the API call
      const paymentResponse = await fetch(
        'https://www.dsctech.in/api/initiate-payment/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(paymentData),
        },
      )

      // Parse the response
      const paymentResult = await paymentResponse.json()

      // Check if the response contains the required payment data
      const paymentResponseData = paymentResult?.payment_response?.data

      if (paymentResponseData?.merchantTransactionId) {
        const {
          merchantTransactionId,
          instrumentResponse,
        } = paymentResponseData

        // Store the transaction ID in localStorage
        localStorage.setItem('merchantTransactionId', merchantTransactionId)

        // Check success status from payment_response
        if (paymentResult.payment_response.success) {
          // Redirect to payment URL if successful
          const redirectUrl = instrumentResponse?.redirectInfo?.url

          if (redirectUrl) {
            window.location.href = redirectUrl
          } else {
            console.error('Redirect URL is missing.')
            setError('Payment redirection failed. Please try again.')
          }
        } else {
          console.error(
            'Payment initiation failed:',
            paymentResult.payment_response.message,
          )
          setError('Failed to initiate payment. Please try again.')
        }
      } else {
        console.error(
          'Payment initiation failed:',
          paymentResult.payment_response.message,
        )
        setError('Failed to initiate payment. Please try again.')
      }
    } catch (error) {
      console.error('Error initiating payment:', error)
      setError(
        'An error occurred while processing the payment. Please try again.',
      )
    } finally {
      setLoading(false) // Stop loading
    }
  }

  return (
    <div
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      {hiddenClass ? (
        <div style={{ width: '88%', marginLeft: 'auto', marginRight: 'auto' }}>
          <div
            onClick={handleClick}
            style={{
              height: hiddenClassmobile ? '5vh' : '7vh',
              width: hiddenClassmobile ? '5vh' : '7vh',
              borderRadius: '50%',
              backgroundColor: '#FABA49',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              marginTop: '6vh',
            }}
          >
            <img
              src={arrow}
              alt="arrow"
              height="50%"
              style={{
                transform: 'rotate(-180deg)',
              }}
            />
          </div>
          <GridContainer>
            {shopDetailsMap.size === 0 ? (
              <div
                style={{
                  width: '100vw',
                  height: '80vh',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                No previous orders
                <img
                  src={pleaselogin}
                  alt="Please login"
                  style={{ marginTop: '5vh' }}
                  height="45%"
                />
              </div>
            ) : (
              [...shopDetailsMap.values()].reverse().map((shop, index) => (
                <GridItem key={shop.id}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      margin: '2vh',
                      marginTop: '1vh',
                    }}
                  >
                    <div
                      style={{
                        height: '3vh',
                        width: 'auto',
                        backgroundColor: '#FADAA1',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '4vw',
                      }}
                    >
                      <div style={{ padding: '0.8vw 2vw', fontSize: '3vw' }}>
                        {shop.booking_status}
                      </div>
                    </div>
                    <div style={{ fontSize: '3vw' }}>
                      Date: {formatDate(shop.date_time)}
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'start' }}>
                    <div
                      style={{
                        height: '14vh',
                        width: '14vh',
                        borderRadius: '6vw',
                        backgroundColor: 'transparent',
                        overflow: 'hidden',
                        margin: '2vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img
                        src={`https://www.dsctech.in/${shop.upload_carshop_image}`}
                        alt={shop.shop_name}
                        style={{
                          height: '100%',
                          width: '100%',
                          borderRadius: '6vw',
                        }}
                      />
                    </div>
                    <div
                      style={{
                        height: '14vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <div style={{ fontSize: '5vw' }}>{shop.shop_name}</div>
                      <div
                        style={{ color: 'rgb(0, 0, 0, 0.6)', fontSize: '3vw' }}
                      >
                        15 kms away
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      margin: '2vh',
                    }}
                  >
                    <div
                      style={{
                        height: '5vh',
                        width: '82%',
                        padding: '0 6.5vw',
                        border: '1px solid #FABA49',
                        borderRadius: '8vw',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                      }}
                    >
                      ₹{shop.cost}
                    </div>
                    <div
                      style={{
                        height: '5vh',
                        width: '82%',
                        padding: '0 6.5vw',
                        border: '1px solid #FABA49',
                        borderRadius: '8vw',
                        display: 'flex',
                        marginTop: '2vh',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                      }}
                      onClick={() => handleCancelOrder(shop.id)}
                    >
                      Cancel Order
                    </div>
                    <div
                      style={{
                        height: '5vh',
                        width: 'auto',
                        padding: '0 6.5vw',
                        border: '1px solid #FABA49',
                        borderRadius: '8vw',
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '2vh',
                        justifyContent: 'center',
                        textAlign: 'center',
                        backgroundColor: '#FABA49',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleClick1(shop.id)}
                    >
                      Track Order
                    </div>
                  </div>
                </GridItem>
              ))
            )}
          </GridContainer>
          <div style={{ height: '5vh' }}></div>
        </div>
      ) : (
        <HideContainer>
          <ShopDetails>Orders</ShopDetails>
          <GridContainer>
            {shopDetailsMap.size === 0 ? (
              <div>No previous orders</div>
            ) : (
              [...shopDetailsMap.values()].reverse().map((shop, index) => (
                <GridItem key={shop.shop_id}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      margin: '2vh',
                    }}
                  >
                    <div
                      style={{
                        height: '4vh',
                        width: 'auto',
                        backgroundColor: '#FADAA1',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '2vw',
                      }}
                    >
                      <div style={{ padding: '0 0.7vw', fontSize: '1vw' }}>
                        {shop.booking_status}
                      </div>
                    </div>
                    <div style={{ fontSize: '1vw' }}>
                      Date: {formatDate(shop.date_time)}
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'start' , marginTop:'1vh'}}>
                    <div
                      style={{
                        height: '21vh',
                        width: '21vh',
                        borderRadius: '2vw',
                        backgroundColor: 'transparent',
                        overflow: 'hidden',
                        margin: '2vh',
                        marginBottom:'0',
                        border: '1px solid #FABA49',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '-0.3vh',
                      }}
                    >
                      <img
                        src={`https://www.dsctech.in/${shop.upload_carshop_image}`}
                        alt={shop.shop_name}
                        style={{
                          height: '17vh',
                          width: '17vh',
                          borderRadius: '1.4vw',
                        }}
                      />
                    </div>
                    <div style={{ marginTop: '6vh' }}>
                      <div style={{ fontSize: '1.7vw' }}>{shop.shop_name}</div>
                      {/* Distance can be calculated or fetched, here it's a placeholder */}
                      <div style={{ color: 'rgb(0, 0, 0, 0.6)' }}>
                        15 kms away
                      </div>
                      {/* <div style={{ color: 'rgb(0, 0, 0, 0.6)' }}>
                        {shop.paymentstatus}
                      </div> */}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      margin: '2vh',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '2vh',
                      }}
                    >
                      {shop.paymentstatus === 'PENDING' ? (
                        <div
                          style={{
                            height: '5vh',
                            width: 'auto',
                            padding: '0 5.5vw',
                            border: '1px solid #FABA49',
                            borderRadius: '2vw',
                            backgroundColor: '#fff7d4',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            cursor: 'pointer',
                          }}
                          // onClick={() => createBooking1(shop.id)}
                        >
                          Pay ₹{shop.cost}
                        </div>
                      ) : (
                        <div
                          style={{
                            height: '5vh',
                            width: 'auto',
                            padding: '0 6.5vw',
                            border: '1px solid #FABA49',
                            borderRadius: '2vw',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                          }}
                        >
                          ₹{shop.cost}
                        </div>
                      )}

                      <div
                        style={{
                          height: '5vh',
                          width: 'auto',
                          padding: '0 6.5vw',
                          border: '1px solid #FABA49',
                          borderRadius: '8vw',
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          justifyContent: 'center',
                          textAlign: 'center',
                        }}
                        onClick={() => handleCancelOrder(shop.id)}
                      >
                        Cancel Order
                      </div>
                    </div>
                    <div
                      style={{
                        height: '5vh',
                        width: 'auto',
                        padding: '0 6.5vw',
                        border: '1px solid #FABA49',
                        borderRadius: '2vw',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        backgroundColor: '#FABA49',
                        
                      }}
                      onClick={() => handleClick1(shop.id)}
                    >
                      Track Order
                    </div>
                  </div>
                </GridItem>
              ))
            )}
          </GridContainer>
        </HideContainer>
      )}
    </div>
  )
}

export default PreviousOrders
