import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom' // Import useParams to access the brand_id from the URL
import axios from 'axios'
import { ReactComponent as SearchIconSVG } from '../../Components/Assets/Search.svg'
import { LoadingSpinner } from '../../Components/LoadingSpinner '
import { useDevice } from '../../Components/Context/deviceContext'
import arrow from '../../Components/Assets/arrow.svg'
const Container = styled.div`
  min-height: 100vh;
`

const InnerContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    width: 88%;
  }
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15vw, 1fr));
  gap: 2vw;
  margin-top: 5vh;
  margin-bottom: 8vh;
  @media (min-width: 501px) and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 5vw;
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 5vw;
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #faba49;
  border-radius: 3vw;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: transform 0.2s;
  padding: 2vw;
  cursor: pointer;

  &:hover {
    transform: scale(1.04);
    background-color: #fadaa1;
  }
  @media (min-width: 501px) and (max-width: 1200px) {
    border-radius: 5vw;
    padding: 3vw;
  }
  @media (max-width: 500px) {
    border-radius: 8vw;
    padding: 3vw;
  }
`

const CardImage = styled.img`
  width: auto;
  height: 12vh;
  background-color: transparent;
  @media (min-width: 501px) and (max-width: 1200px) {
    height: 11vh;
  }

  @media (max-width: 500px) {
    height: 9vh;
  }
`

const Title = styled.div`
  font-size: 1.2vw;
  font-weight: 400;
  margin-top: 1.5vh;
  text-align: center;
  line-height: 1.2;
  letter-spacing: 2px;
  @media (min-width: 501px) and (max-width: 1200px) {
    font-size: 3.1vw;
     margin-bottom: 1.5vh;
  }

  @media (max-width: 500px) {
    font-size: 4vw;
    margin-bottom: 1.5vh;
  }
`

const SearchContainer = styled.div`
  width: 40vw;
  margin-top: 2vh;
  @media (min-width: 501px) and (max-width: 1200px) {
    width: 83.5%;
  }
  @media (max-width: 500px) {
    width: 83.5%;
  }
`

const SearchInput = styled.input`
  padding: 0.7vw 2.5vw; /* Adjusted padding to make space for the icon */
  width: 100%;
  border-radius: 4vw;
  font-size: 1.1vw;
  border: 1px solid #faba49;
  transition: border-color 0.3s;
  &:focus {
    background-color: #fff7d4;
    border: 1px solid #faba49;
    outline: none;
  }
  @media (min-width: 501px) and (max-width: 1200px) {
    padding: 2vw 7vw;
    font-size: 3.8vw;
  }
  @media (max-width: 500px) {
    padding: 2vw 7vw;
    font-size: 3.8vw;
  }
`

const SearchIcon = styled(SearchIconSVG)`
  position: absolute;
  top: 24vh;
  left: 5.8vw;
  transform: translateY(-50%);
  width: 1.2vw;
  height: 1.2vw;
  color: #faba49;
`

const Button = styled.button`
  padding: 0.7vw 1.3vw;
  margin-top: 2vh;
  background-color: #faba49;
  color: #000;
  font-size: 1.1vw;
  border: none;
  border-radius: 4vw;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 501px) and (max-width: 1200px) {
    margin-top: 2vh;
    padding: 1.2vw 2.2vw;
    font-size:  2.6vw;
    letter-spacing: 0.2vw;
  }
  @media (max-width: 500px) {
    margin-top: 2vh;
    padding: 1.6vw 2.6vw;
    font-size: 4vw;
    letter-spacing: 0.2vw; 
  }
`

const Input = styled.input`
  padding: 0.8vw;
  border-radius: 3vw;
  background-color: #f2f2f2;
  border: 1px solid #ff8c00;
  font-size: 1.2vw;
  &:focus {
    background-color: #fff7d4;
    border: 1px solid #ff8c00;
    outline: none;
  }

  @media (min-width: 501px) and (max-width: 1200px) {
    font-size: 3.1vw;
    padding: 2vw;
    padding-left: 3vw;
    border-radius: 5vw;
  }

  @media (max-width: 500px) {
    font-size: 4vw;
    border-radius: 8vw;
    padding: 2vw;
    padding-left: 4vw;
  }
`

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen1 }) => (isOpen1 ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const DialogBox = styled.div`
  background: #fff;
  border-radius: 1vw;
  width: 30vw;
  padding: 2vw;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  @media (min-width: 501px) and (max-width: 1200px) {
    width: 80vw;
    max-height: 70vh;
    padding-left:5vw;
    padding: 3vw;
  }

  @media (max-width: 500px) {
    width: 80vw;
    max-height: 80vh;
    padding-left:5vw;
    padding: 4vw;
  }
`

const DialogHeader = styled.div`
  font-size: 1.5vw;
  font-weight: bold;
  margin-bottom: 1.5vh;
`

const DialogContent = styled.div`
  margin-top: 1vh;
`

const DialogActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2vh;
`

const CarOption = styled.div`
  border: 1px solid #faba49;
  padding: 0.8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 1vw;
  text-transform: none;
  border-radius: 3vw;
  background-color: ${(props) => (props.isSelected ? '#faba49' : '')};
  &:hover {
    color: black;
    background-color: ${(props) => (props.isSelected ? '#faba49' : '#fff7d4')};
  }
  @media (min-width: 501px) and (max-width: 1200px) {
    font-size: 3.1vw;
    padding: 1.4vw;
    border-radius: 5vw;
  }

  @media (max-width: 500px) {
    font-size: 4vw;
    border-radius: 8vw;
    padding: 1.5vw;
  }
`
const Heading = styled.div`
  font-size: 1.2vw;
  margin-bottom: 2vh;
  font-weight: 400;
  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 3.8vw;
    letter-spacing: 0.5vw;
  }
  @media (max-width: 767px) {
    font-size: 3.4vw;
    letter-spacing: 0.5vw;
  }
`

const CarBrandFamilyPage = () => {
  const { brand_id } = useParams()
  const parsedBrandId = parseInt(brand_id, 10)
  const [family, setFamily] = useState([])
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [openDialog, setOpenDialog] = useState(false)
  const [carType, setCarType] = useState('')
  const [vehicleNumber, setVehicleNumber] = useState('')
  const { phoneNumber } = useDevice()

  const navigate = useNavigate()
   const [hiddenClass, setHiddenClass] = useState('')
    const [hiddenClassmobile, setHiddenClassmobile] = useState('')
  
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 1200) {
          setHiddenClass('hidden')
        } else {
          setHiddenClass('')
        }
      }
  
      window.addEventListener('resize', handleResize)
      handleResize() // Initial check
  
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [])
  
    useEffect(() => {
      const handleResizemobile = () => {
        if (window.innerWidth < 500) {
          setHiddenClassmobile('hidden')
        } else {
          setHiddenClassmobile('')
        }
      }
  
      window.addEventListener('resize', handleResizemobile)
      handleResizemobile() // Initial check
  
      return () => {
        window.removeEventListener('resize', handleResizemobile)
      }
    }, [])

  useEffect(() => {
    // Fetch car brand family data based on the brand_id
    axios
      .get(`https://www.dsctech.in/carshops/brand/${brand_id}/family/`)
      .then((response) => {
        setFamily(response.data)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching car brand family:', error)
        setLoading(false)
      })
  }, [brand_id])

  const filteredFamily = family.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase()),
  )

  if (loading) return <LoadingSpinner />

  const handleOpenDialog = () => {
    setOpenDialog(true) // Open the dialog when button is clicked
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setCarType('')
    setVehicleNumber('')
  }

  const handleAddCarDetails = () => {
    setLoading(true) // Set loading to true when the request starts

    const carDetails = {
      phone: phoneNumber,
      custom_name: vehicleNumber,
      vehicle_type: carType,
    }

    axios
      .post('https://www.dsctech.in/carshops/vehicles/add/', carDetails)
      .then((response) => {
        if (response.data && response.data.vehicle) {
          const vehicleId = response.data.vehicle.id
          const vehicle_type = response.data.vehicle.brand_family.vehicle_type
          localStorage.setItem('lastCarDetailId', vehicleId)
          localStorage.setItem('vehicle_type', vehicle_type)
        }
        handleCloseDialog() // Close the dialog
        navigate('/') // Navigate to the home page
        setLoading(false) // Set loading to false after the request completes
      })
      .catch((error) => {
        console.error('Error adding car details:', error)
        setLoading(false) // Set loading to false in case of an error
        // You can show an error message here if needed
      })
  }

  const handleCardClick = async (itemId) => {
    // Make POST request when a card is clicked
    const postData = {
      phone: phoneNumber,
      brand_id: parsedBrandId,
      brand_family_id: itemId,
    }

    setLoading(true)

    try {
      const response = await axios.post(
        'https://www.dsctech.in/carshops/vehicles/add/',
        postData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      if (response.data && response.data.vehicle) {
        const vehicleId = response.data.vehicle.id
        const vehicle_type = response.data.vehicle.brand_family.vehicle_type
        localStorage.setItem('lastCarDetailId', vehicleId)
        localStorage.setItem('vehicle_type', vehicle_type)
        navigate('/')
      }
    } catch (error) {
      console.error('Error posting data:', error)
      alert('Failed to submit data.')
    } finally {
      setLoading(false) // Stop loading
    }
  }

  
  const handleClick = () => {
    navigate(-1)
  }

  return (
    <Container>
      {hiddenClass ? (
        <InnerContainer>
        <div
          style={{
            display: 'flex',
            flexDirection:'column',
            alignItems: 'start',
            justifyContent: 'space-between',
            marginTop: '4vh',
          }}
        >
            <div
            onClick={handleClick}
            style={{
              height: hiddenClassmobile ? '5vh' : '7vh',
              width: hiddenClassmobile ? '5vh' : '7vh',
              borderRadius: '50%',
              backgroundColor: '#FABA49',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              marginTop: '2vh',
              marginBottom:'2vh'
            }}
          >
            <img
              src={arrow}
              alt="arrow"
              height="50%"
              style={{
                transform: 'rotate(-180deg)',
              }}
            />
          </div>
          <SearchContainer>
            <SearchIcon />
            <SearchInput
              type="text"
              placeholder="Search car brands..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </SearchContainer>
          <Button onClick={handleOpenDialog}>Cannot find your Car ?</Button>
        </div>

        <GridContainer>
          {filteredFamily.map((item) => (
            <Card key={item.id} onClick={() => handleCardClick(item.id)}>
              <CardImage
                src={`https://www.dsctech.in${item.image}`}
                alt={item.name}
              />
              <Title>{item.name}</Title>
            </Card>
          ))}
        </GridContainer>

        <DialogOverlay isOpen1={openDialog}>
          <DialogBox>
            <Heading>Select your Car Type</Heading>
            <div
              style={{
                marginTop: '1.5vh',
                display: 'flex',
                flexDirection: 'column',
                gap: '2vh',
                marginBottom: '4vh',
              }}
            >
              <CarOption
                isSelected={carType === 'Hatchback'}
                onClick={() => setCarType('Hatchback')}
              >
                Hatchback
              </CarOption>
              <CarOption
                isSelected={carType === 'Sedan'}
                onClick={() => setCarType('Sedan')}
              >
                Sedan
              </CarOption>
              <CarOption
                isSelected={carType === 'SUV/MUV'}
                onClick={() => setCarType('SUV/MUV')}
              >
                SUV or MUV
              </CarOption>
            </div>
            <Heading>Please Enter your Vehicle Number</Heading>
            <Input
              type="text"
              placeholder="Vehicle Number"
              required
              value={vehicleNumber}
              style={{ width: '94.5%' }}
              onChange={(e) => setVehicleNumber(e.target.value)}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '3vh',
              }}
            >
              <Button
                onClick={handleCloseDialog}
                style={{ backgroundColor: '#fff7d4' }}
              >
                Cancel
              </Button>
              <Button onClick={handleAddCarDetails}>Add Car</Button>
            </div>
          </DialogBox>
        </DialogOverlay>
      </InnerContainer>
      ): (
        <InnerContainer>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '4vh',
          }}
        >
          <SearchContainer>
            <SearchIcon />
            <SearchInput
              type="text"
              placeholder="Search car brands..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </SearchContainer>
          <Button onClick={handleOpenDialog}>Cannot find your Car ?</Button>
        </div>

        <GridContainer>
          {filteredFamily.map((item) => (
            <Card key={item.id} onClick={() => handleCardClick(item.id)}>
              <CardImage
                src={`https://www.dsctech.in${item.image}`}
                alt={item.name}
              />
              <Title>{item.name}</Title>
            </Card>
          ))}
        </GridContainer>

        <DialogOverlay isOpen1={openDialog}>
          <DialogBox>
            <Heading>Select your Car Type</Heading>
            <div
              style={{
                marginTop: '1.5vh',
                display: 'flex',
                flexDirection: 'column',
                gap: '2vh',
                marginBottom: '4vh',
              }}
            >
              <CarOption
                isSelected={carType === 'Hatchback'}
                onClick={() => setCarType('Hatchback')}
              >
                Hatchback
              </CarOption>
              <CarOption
                isSelected={carType === 'Sedan'}
                onClick={() => setCarType('Sedan')}
              >
                Sedan
              </CarOption>
              <CarOption
                isSelected={carType === 'SUV/MUV'}
                onClick={() => setCarType('SUV/MUV')}
              >
                SUV or MUV
              </CarOption>
            </div>
            <Heading>Please Enter your Vehicle Number</Heading>
            <Input
              type="text"
              placeholder="Vehicle Number"
              required
              value={vehicleNumber}
              style={{ width: '94.5%' }}
              onChange={(e) => setVehicleNumber(e.target.value)}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '3vh',
              }}
            >
              <Button
                onClick={handleCloseDialog}
                style={{ backgroundColor: '#fff7d4' }}
              >
                Cancel
              </Button>
              <Button onClick={handleAddCarDetails}>Add Car</Button>
            </div>
          </DialogBox>
        </DialogOverlay>
      </InnerContainer>
      )}
    </Container>
  )
}

export default CarBrandFamilyPage
