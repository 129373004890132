import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './Pages/Home/Home'
import Announcement from './Components/Announcement/Announcement'
import Navbar from './Components/Navbar/Navbar'
import Login from './Pages/Login/Login'
import CarWashDetails from './Pages/CarWashDetails '
import Previousorders from './Pages/Previousorders'
import Profile from './Pages/Profile'
import { useDevice } from './Components/Context/deviceContext'
import Aboutus from './Pages/Aboutus'
import Savedaddress from './Pages/savedaddress'
import Savecar from './Pages/savedcars'
import Driver from './Pages/Login/Driver'
import Patnerlogin from './Pages/Login/patnerlogin'
import Driverhome from './Pages/Home/Driverhome'
import DriverOrders from './Pages/DriverOrders'
import Driverdetails from './Pages/Driverdetails'
import Driverprofile from './Pages/Driverprofile'
import CarShopForm from './Pages/Patnerdetails'
import PatnerHome from './Pages/Home/PatnerHome'
import Patnerorders from './Pages/patnerorders'
import Patnerprofile from './Pages/Patnerprofile'
import Footer from './Components/fotter'
import TermsAndConditions from './Components/TermsAndConditions'
import RefundPolicy from './Components/RefundPolicy'
import PrivacyPolicy from './Components/PrivacyPolicy'
import Mock from './Pages/Mock'
import Driverupload from './Pages/Driverupload'
import { generateToken, messaging } from './notifications/firebase'; // Import messaging
import { onMessage } from 'firebase/messaging';
import Newhome from './Pages/Home/Newhome'
import CarBrandPage from './Pages/CarBrandPage'
import CarBrandFamilyPage from './Pages/Home/CarBrandFamilyPage'
import Shop from './Pages/Shop'



function App() {
  const [hiddenClass, setHiddenClass] = useState('')
  const [role, setRole] = useState('') // Store role from localStorage

  useEffect(() => {
    generateToken();
    onMessage(messaging, (payload) => {
      console.log(payload);
    });
  }, []);
  
  
  
  useEffect(() => {
    const handleResize = () => {
      const storedPhoneNumber = localStorage.getItem('phoneNumber')
      if (window.innerWidth < 1200 && !storedPhoneNumber) {
        setHiddenClass('hidden')
      } else {
        setHiddenClass('')
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Retrieve the role from localStorage on initial load
  useEffect(() => {
    const storedRole = localStorage.getItem('role')
    setRole(storedRole)
  }, [])

  const userId = "114"

  return (
    
    <Router>
      <Announcement />
      <Navbar />
      <Routes>
        <Route
          path="/"  exact 
          element={
            hiddenClass ? (
              <Login />
            ) : role === 'driver' ? (
              <Driverhome />
            ) : role === 'owner' ? (
              <PatnerHome />
            ) : (
              <Newhome />
            )
          }
        />

        <Route
          path="/login"
          element={
            role === 'driver' ? <Driver /> :
            role === 'owner' ? <Patnerlogin /> : 
            <Login />
          }
        />
        <Route
          path="/profile"
          element={role === 'driver' ? <Driverprofile /> :role === 'owner' ? <Patnerprofile /> : <Profile />}
        />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/carwash/:id" element={<CarWashDetails />} />
        <Route path="/booking/:id" element={<Mock />} />
        <Route path="/savedaddress" element={<Savedaddress />} />
        <Route path="/savedcar" element={<Savecar />} />
        <Route path="/driver" element={<Driver />} />
        <Route path="/driverupload/:id" element={<Driverupload />} />
        <Route path="/adddriverdetais" element={<Driverdetails />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/addownerdetais" element={<CarShopForm />} />
        <Route path="/carbrand" element={<CarBrandPage />} />
        <Route path="/carbrandfamily/:brand_id" element={<CarBrandFamilyPage />} />
        <Route path="/partnerlogin" element={<Patnerlogin />} />
        <Route path="/shops/:id" element={<Shop />} />
        {/* Conditionally render DriverOrders or Previousorders based on role */}
        {role === 'driver' ? (
          <Route path="/orders" element={<DriverOrders />} />
        ) : role === 'owner' ? (
          <Route path="/orders" element={<Patnerorders />} />
        ) : (
          <Route path="/orders" element={<Previousorders />} />
        )}
       
      </Routes>
      {/* {hiddenClass ? (<div></div>): (
        <Footer/>
      )} */}
    </Router>
  )
}

export default App
