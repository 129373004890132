import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { LoadingSpinner } from '../Components/LoadingSpinner '
import { useDevice } from '../Components/Context/deviceContext'
import pleaselogin from '../Components/Assets/pleaselogin.svg'
import { useNavigate } from 'react-router-dom'
import arrow from '../Components/Assets/arrow.svg'
import Footer from '../Components/fotter'

const GridContainer = styled.div`
  display: grid;
  height: auto;
  gap: 5vh;
  margin-top: 2%;
  margin-bottom: 6vh;
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: 501px) and (max-width: 1200px) {
    margin-top: 2vh;
    grid-template-columns: repeat(1, 1fr);
    gap: 2vh;
  }
  @media (max-width: 500px) {
    margin-top: 2vh;
    grid-template-columns: repeat(1, 1fr);
    gap: 2vh;
  }
`

const Heading1 = styled.div`
  font-size: 2vw;
  font-weight: 400;
  @media (min-width: 501px) and (max-width: 1200px) {
    font-size: 3.8vw;
    font-wieght: 600;
    letter-spacing: 0.5vw;
  }
  @media (max-width: 500px) {
    font-size: 4.7vw;
    font-wieght: 700;
    letter-spacing: 0.5vw;
  }
`

const GridItem = styled.div`
  border: 1.5px solid #faba49;
  cursor: pointer;
  border-radius: 3vw;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2vw;
  @media (min-width: 501px) and (max-width: 1200px) {
    border-radius: 4vw;
    padding: 4vw;
  }

  @media (max-width: 500px) {
    border-radius: 7vw;
    padding: 5vw;
  }
`
const Patnerorders = () => {
  const navigate = useNavigate()
  const { phoneNumber } = useDevice()
  const [carshopData, setCarshopData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [isPhonePresent, setIsPhonePresent] = useState(false)
  const [hiddenClass, setHiddenClass] = useState('')
  const [hiddenClassmobile, setHiddenClassmobile] = useState('')

  const handleBack = () => {
    window.location.reload(); // Reload the current page
    navigate(-1); // Then navigate back
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setHiddenClass('hidden')
      } else {
        setHiddenClass('')
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handleResizemobile = () => {
      if (window.innerWidth < 500) {
        setHiddenClassmobile('hidden')
      } else {
        setHiddenClassmobile('')
      }
    }

    window.addEventListener('resize', handleResizemobile)
    handleResizemobile() // Initial check

    return () => {
      window.removeEventListener('resize', handleResizemobile)
    }
  }, [])

  useEffect(() => {
    const fetchCarShopData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://www.dsctech.in/api/carshop/${phoneNumber}/`
        );
        setCarshopData(response.data.carshop);
      } catch (err) {
        console.error('Error fetching car shop data:', err);
      } finally {
        setLoading(false);
      }
    };

    if (phoneNumber) {
      setIsPhonePresent(true);
      fetchCarShopData();
    } else {
      setIsPhonePresent(false);
    }
  }, [phoneNumber]);

  if (!isPhonePresent) {
    return (
      <div
        style={{
          width: '100%',
          height: hiddenClass ? '100vh' : '80vh',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {hiddenClass ? (
          <div>
            <button
              onClick={handleBack} // Call handleBack on button click
              style={{
                position: 'absolute',
                top: '7vh',
                left: '7%',
                marginTop: '10px',
                padding: '15px',
                borderRadius: '30px',
                border: '1px solid #ffffff',
                backgroundColor: '#FADAA1',
                color: 'black',
                cursor: 'pointer',
                marginRight: '10px',
              }}
            >
              Back
            </button>
          </div>
        ) : (
          <div></div>
        )}
        Please login to view your previous orders.
        <img
          src={pleaselogin}
          alt="Please login"
          style={{ marginTop: '5vh' }}
          height="45%"
        />
      </div>
    )
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
   <div>
     <div
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      {hiddenClass ? (
        <div style={{ width: '88%', marginLeft: 'auto', marginRight: 'auto' }}>
            <div
            onClick={handleBack}
            style={{
              height: hiddenClassmobile ? '5vh' : '7vh',
              width: hiddenClassmobile ? '5vh' : '7vh',
              borderRadius: '50%',
              backgroundColor: '#FABA49',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              marginTop: '6vh',
            }}
          >
            <img
              src={arrow}
              alt="arrow"
              height="50%"
              style={{
                transform: 'rotate(-180deg)',
              }}
            />
          </div>
          <Heading1 style={{ marginTop: '5vh' }}>Driver Bookings</Heading1>
          <GridContainer>
                {carshopData.bookings.length > 0 ? (
                  carshopData.bookings.map((booking) => (
                    <GridItem key={booking.id}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            color: 'rgb(0, 0, 0, 1)',
                            fontSize: hiddenClassmobile ? '3vw' : '2vw' ,
                            height: '4.5vh',
                            backgroundColor: '#FADAA1',
                            width: 'auto',
                            paddingLeft: '2.5vw',
                            paddingRight: '2.5vw',
                            borderRadius: '5vw',
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                          }}
                        >
                          {booking.booking_status}{' '}
                        </div>
                        <div
                          style={{
                            color: 'rgb(0, 0, 0, 0.6)',
                            fontSize: hiddenClassmobile ? '3vw' : '2vw' ,
                          }}
                        >
                          <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                            Date :{' '}
                          </span>{' '}
                          {new Date(booking.booking_date).toLocaleDateString(
                            'en-GB',
                            {
                              day: 'numeric',
                              month: 'short',
                            },
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          color: 'rgb(0, 0, 0, 0.6)',
                          marginTop: '1vh',
                          fontSize: hiddenClassmobile ? '3vw' : '2vw' ,
                        }}
                      >
                        <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                          Customer :
                        </span>{' '}
                        {booking.customer.name}
                      </div>
                      <div
                        style={{
                          color: 'rgb(0, 0, 0, 0.6)',
                          marginTop: '1vh',
                          fontSize: hiddenClassmobile ? '3vw' : '2vw' ,
                        }}
                      >
                        <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Car :</span>{' '}
                        {booking.vehicle.brand_family} ({booking.vehicle.brand})
                      </div>
                      <div
                        style={{
                          color: 'rgb(0, 0, 0, 0.6)',
                          marginTop: '1vh',
                          fontSize: hiddenClassmobile ? '3vw' : '2vw' ,
                        }}
                      >
                        <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                          Selected Slot :
                        </span>{' '}
                        {booking.selected_slot}
                      </div>
                      <div
                        style={{
                          color: 'rgb(0, 0, 0, 0.6)',
                          marginTop: '1vh',
                          fontSize: hiddenClassmobile ? '3vw' : '2vw' ,
                        }}
                      >
                        <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                          Service :
                        </span>{' '}
                        {booking.service.service_name} (Cost:{' '}
                        {booking.service.cost})
                      </div>
                    </GridItem>
                  ))
                ) : (
                  <p>No bookings available.</p>
                )}
              </GridContainer>

        </div>
      ) : (
        <div style={{ width: '90%', marginLeft: '5%', marginRight: '5%' }}>
          {carshopData && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  fontSize: '1.8vw',
                  fontWeight: '500',
                  marginTop: '5vh',
                }}
              >
                {' '}
                Bookings
              </div>
              <GridContainer>
                {carshopData.bookings.length > 0 ? (
                  carshopData.bookings.map((booking) => (
                    <GridItem key={booking.id}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            color: 'rgb(0, 0, 0, 1)',
                            fontSize: '1vw',
                            height: '4.5vh',
                            backgroundColor: '#FADAA1',
                            width: 'auto',
                            paddingLeft: '1.5vw',
                            paddingRight: '1.5vw',
                            borderRadius: '2vw',
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                          }}
                        >
                          {booking.booking_status}{' '}
                        </div>
                        <div
                          style={{
                            color: 'rgb(0, 0, 0, 0.6)',
                            fontSize: '1.2vw',
                          }}
                        >
                          <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                            Date :{' '}
                          </span>{' '}
                          {new Date(booking.booking_date).toLocaleDateString(
                            'en-GB',
                            {
                              day: 'numeric',
                              month: 'short',
                            },
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          color: 'rgb(0, 0, 0, 0.6)',
                          marginTop: '1vh',
                          fontSize: '1.2vw',
                        }}
                      >
                        <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                          Customer :
                        </span>{' '}
                        {booking.customer.name}
                      </div>
                      <div
                        style={{
                          color: 'rgb(0, 0, 0, 0.6)',
                          marginTop: '1vh',
                          fontSize: '1.2vw',
                        }}
                      >
                        <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Car :</span>{' '}
                        {booking.vehicle.brand_family} ({booking.vehicle.brand})
                      </div>
                      <div
                        style={{
                          color: 'rgb(0, 0, 0, 0.6)',
                          marginTop: '1vh',
                          fontSize: '1.2vw',
                        }}
                      >
                        <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                          Selected Slot :
                        </span>{' '}
                        {booking.selected_slot}
                      </div>
                      <div
                        style={{
                          color: 'rgb(0, 0, 0, 0.6)',
                          marginTop: '1vh',
                          fontSize: '1.2vw',
                        }}
                      >
                        <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                          Service :
                        </span>{' '}
                        {booking.service.service_name} (Cost:{' '}
                        {booking.service.cost})
                      </div>
                    </GridItem>
                  ))
                ) : (
                  <p>No bookings available.</p>
                )}
              </GridContainer>
            </div>
          )}
        </div>
      )}
    </div>
   </div>
  )
}

export default Patnerorders
