import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { useDevice } from '../../Components/Context/deviceContext'
import { LoadingSpinner } from '../../Components/LoadingSpinner '
import { useNavigate } from 'react-router-dom'
import pleaselogin from '../../Components/Assets/pleaselogin.svg'
import profile from '../../Components/Assets/profile.svg'
import Footer from '../../Components/fotter'

const GridContainer = styled.div`
  display: grid;
  height: auto;
  gap: 3vh;
  height: auto;
  margin-top: 6%;
  margin-bottom: 5vh;
  grid-template-columns: repeat(2, 1fr);

  @media (min-width: 501px) and (max-width: 1200px) {
    margin-top: 2vh;
    grid-template-columns: repeat(1, 1fr);
    gap: 2vh;
  }
  @media (max-width: 500px) {
    margin-top: 2vh;
    grid-template-columns: repeat(1, 1fr);
    gap: 2vh;
  }
`

const Heading1 = styled.div`
  font-size: 2vw;
  font-weight: 400;
  @media (min-width: 501px) and (max-width: 1200px) {
    font-size: 3.8vw;
    font-wieght: 600;
    letter-spacing: 0.5vw;
  }
  @media (max-width: 500px) {
    font-size: 4.7vw;
    font-wieght: 700;
    letter-spacing: 0.5vw;
  }
`

const GridItem = styled.div`
  border: 1.5px solid #faba49;
  cursor: pointer;
  border-radius: 3vw;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2vw;
  @media (min-width: 501px) and (max-width: 1200px) {
    border-radius: 4vw;
    padding: 4vw;
  }

  @media (max-width: 500px) {
    border-radius: 7vw;
    padding: 5vw;
  }
`

const StyledDiv4 = styled.div`
  margin-left: 5%;
  margin-top: 2.5vh;
  font-size: 6vw;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #faba49;
  }
`

const StyledButton1 = styled.button`
  cursor: pointer;
  padding: 0rem 1.2rem;
  height: 32px;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  letter-spacing: 0.05rem;
  font-weight: 400;
  font-size: 16px;
  border-radius: 400px;
  border: 2px solid #faba49;
  overflow: hidden;
  background: #faba49;
  color: black;
  transition: color 0.4s;
  position: relative;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    content: '';
  }

  &::before {
    background: #fadaa1;

    width: 120%;
    left: -10%;
    transform: skew(30deg);
    transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
  }

  &:hover::before {
    transform: translate3d(100%, 0, 0);
  }

  &:hover span {
    color: black;
  }

  span {
    position: relative;
    z-index: 10;
    transition: color 0.4s;
  }
`

const PatnerHome = () => {
  const navigate = useNavigate()
  const { phoneNumber } = useDevice()
  const [carshopData, setCarshopData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isPhonePresent, setIsPhonePresent] = useState(false)
  const [error, setError] = useState(null)
  const [hiddenClass, setHiddenClass] = useState('')
  const [hiddenClassmobile, setHiddenClassmobile] = useState('')
  const [isActive, setIsActive] = useState(false)

  const handleBack = () => {
    window.location.reload() // Reload the current page
    navigate(-1) // Then navigate back
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setHiddenClass('hidden')
      } else {
        setHiddenClass('')
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handleResizemobile = () => {
      if (window.innerWidth < 500) {
        setHiddenClassmobile('hidden')
      } else {
        setHiddenClassmobile('')
      }
    }

    window.addEventListener('resize', handleResizemobile)
    handleResizemobile() // Initial check

    return () => {
      window.removeEventListener('resize', handleResizemobile)
    }
  }, [])

  const fetchCarShopData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        `https://www.dsctech.in/api/carshop/${phoneNumber}/`,
      )
      setCarshopData(response.data.carshop)
      setLoading(false)
    } catch (err) {
      console.error('Error fetching car shop data:', err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (phoneNumber) {
      setIsPhonePresent(true)
      fetchCarShopData()
    } else {
      setIsPhonePresent(false)
    }
  }, [phoneNumber])

  if (!isPhonePresent) {
    return (
      <div
        style={{
          width: '100%',
          height: hiddenClass ? '100vh' : '80vh',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {hiddenClass ? (
          <div>
            <button
              onClick={handleBack} // Call handleBack on button click
              style={{
                position: 'absolute',
                top: '7vh',
                left: '7%',
                marginTop: '10px',
                padding: '15px',
                borderRadius: '30px',
                border: '1px solid #ffffff',
                backgroundColor: '#FADAA1',
                color: 'black',
                cursor: 'pointer',
                marginRight: '10px',
              }}
            >
              Back
            </button>
          </div>
        ) : (
          <div></div>
        )}
        Please login to view your previous orders.
        <img
          src={pleaselogin}
          alt="Please login"
          style={{ marginTop: '5vh' }}
          height="45%"
        />
      </div>
    )
  }
  const navLinks = [
    { name: 'Home', path: '/' },
    { name: 'About Us', path: '/aboutus' },
    { name: 'My Bookings', path: '/orders' },
    { name: 'Share App', path: '#' }, // Placeholder path for the Share App button
  ]

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Check out this app!',
          text: 'I thought you might like this app. Check it out!',
          url: window.location.href,
        })
        .then(() => console.log('Thanks for sharing!'))
        .catch((error) => console.log('Error sharing:', error))
    } else if (window.flutter_inappwebview) {
      // Notify Flutter to handle sharing
      window.flutter_inappwebview.callHandler('shareApp')
    } else {
      alert(
        'Web Share API not supported. Copy the URL manually: ' +
          window.location.href,
      )
    }
  }

  const handleNavigation = (path) => {
    navigate(path)
  }

  const handleLogout = () => {
    // Remove phone number and role from localStorage
    setIsActive(false);
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('latitude')
    localStorage.removeItem('longitude')
    localStorage.removeItem('role'); 
    navigate('/login');
  }
  

  const handleprofileClick = () => {
    navigate(`/profile`)
  }

  if (loading) {
    return <LoadingSpinner />
  }

  const isTodayInIndia = (date) => {
    const indiaDate = new Date().toLocaleDateString('en-IN', {
      timeZone: 'Asia/Kolkata',
    })
    const bookingDate = new Date(date).toLocaleDateString('en-IN', {
      timeZone: 'Asia/Kolkata',
    })
    return indiaDate === bookingDate
  }

  return (
    <div>
      <div style={{ minHeight: '100vh' }}>
        {hiddenClass ? (
          <div style={{ width: '90%', marginLeft: '5%', marginRight: '5%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '8vh',
              }}
            >
              <div
                onClick={() => setIsActive(!isActive)}
                className="button"
                style={{ marginLeft: '0', zIndex: '7' }}
              >
                <div
                  className={`burger ${isActive ? 'burgerActive' : ''}`}
                ></div>
              </div>
              <div
                className={`dialog ${isActive ? 'dialogActive' : ''}`}
                style={{ zIndex: '6' }}
              >
                <div style={{ marginTop: '20vh' }}>
                  {navLinks.map((link, index) =>
                    link.name === 'Share App' ? (
                      <StyledDiv4 key={index} onClick={handleShare}>
                        {link.name}
                      </StyledDiv4>
                    ) : (
                      <StyledDiv4
                        key={index}
                        onClick={() => handleNavigation(link.path)}
                      >
                        {link.name}
                      </StyledDiv4>
                    ),
                  )}
                </div>
                <StyledButton1
                  style={{
                    marginRight: '5%',
                    width: '90%',
                    height: '6%',
                    marginLeft: '5%',
                    marginTop: '3.5vh',
                  }}
                  onClick={handleLogout}
                >
                  <span>Logout</span>
                </StyledButton1>
              </div>
              <div
                style={{
                  height: '6vh',
                  width: '6vh',
                  border: '1px solid #FABA49',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={handleprofileClick}
              >
                <img src={profile} height="60%" width="auto" />
              </div>
            </div>
            <Heading1 style={{ marginTop: '5vh' }}> Bookings</Heading1>

            <GridContainer>
              {carshopData &&
              carshopData.bookings &&
              carshopData.bookings.filter((booking) =>
                isTodayInIndia(booking.booking_date),
              ).length > 0 ? (
                carshopData.bookings
                  .filter((booking) => isTodayInIndia(booking.booking_date))
                  .map((booking) => (
                    <GridItem key={booking.id}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            color: 'rgb(0, 0, 0, 1)',
                            fontSize: hiddenClassmobile ? '3vw' : '2vw',
                            height: '4.5vh',
                            backgroundColor: '#FADAA1',
                            width: 'auto',
                            paddingLeft: '2.5vw',
                            paddingRight: '2.5vw',
                            borderRadius: '5vw',
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                          }}
                        >
                          {booking.booking_status}{' '}
                        </div>
                        <div
                          style={{
                            color: 'rgb(0, 0, 0, 0.6)',
                            fontSize: hiddenClassmobile ? '3vw' : '2vw',
                          }}
                        >
                          <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                            Date :{' '}
                          </span>{' '}
                          {new Date(booking.booking_date).toLocaleDateString(
                            'en-GB',
                            {
                              day: 'numeric',
                              month: 'short',
                            },
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          color: 'rgb(0, 0, 0, 0.6)',
                          marginTop: '1vh',
                          fontSize: hiddenClassmobile ? '3vw' : '2vw',
                        }}
                      >
                        <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                          Customer :
                        </span>{' '}
                        {booking.customer.name}
                      </div>
                      <div
                        style={{
                          color: 'rgb(0, 0, 0, 0.6)',
                          marginTop: '1vh',
                          fontSize: hiddenClassmobile ? '3vw' : '2vw',
                        }}
                      >
                        <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Car :</span>{' '}
                        {booking.vehicle.brand_family} ({booking.vehicle.brand})
                      </div>
                      <div
                        style={{
                          color: 'rgb(0, 0, 0, 0.6)',
                          marginTop: '1vh',
                          fontSize: hiddenClassmobile ? '3vw' : '2vw',
                        }}
                      >
                        <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                          Selected Slot :
                        </span>{' '}
                        {booking.selected_slot}
                      </div>
                      <div
                        style={{
                          color: 'rgb(0, 0, 0, 0.6)',
                          marginTop: '1vh',
                          fontSize: hiddenClassmobile ? '3vw' : '2vw',
                        }}
                      >
                        <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                          Service :
                        </span>{' '}
                        {booking.service.service_name} (Cost:{' '}
                        {booking.service.cost})
                      </div>
                    </GridItem>
                  ))
              ) : (
                <p>No bookings available.</p>
              )}
            </GridContainer>
          </div>
        ) : (
          <div style={{ width: '90%', marginLeft: '5%', marginRight: '5%', }}>
            {carshopData && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{
                      fontSize: '1.8vw',
                      fontWeight: '500',
                      marginTop: '5vh',
                    }}
                  >
                    {' '}
                    Today Bokkings
                  </div>
                  <GridContainer>
                    {carshopData.bookings.filter((booking) =>
                      isTodayInIndia(booking.booking_date),
                    ).length > 0 ? (
                      carshopData.bookings
                        .filter((booking) =>
                          isTodayInIndia(booking.booking_date),
                        )
                        .map((booking) => (
                          <GridItem key={booking.id}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div
                                style={{
                                  color: 'rgb(0, 0, 0, 1)',
                                  fontSize: '1vw',
                                  height: '4.5vh',
                                  backgroundColor: '#FADAA1',
                                  width: 'auto',
                                  paddingLeft: '1.5vw',
                                  paddingRight: '1.5vw',
                                  borderRadius: '2vw',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  display: 'flex',
                                }}
                              >
                                {booking.booking_status}{' '}
                              </div>
                              <div
                                style={{
                                  color: 'rgb(0, 0, 0, 0.6)',
                                  fontSize: '1.2vw',
                                }}
                              >
                                <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                                  Date :{' '}
                                </span>{' '}
                                {new Date(
                                  booking.booking_date,
                                ).toLocaleDateString('en-GB', {
                                  day: 'numeric',
                                  month: 'short',
                                })}
                              </div>
                            </div>
                            <div
                              style={{
                                color: 'rgb(0, 0, 0, 0.6)',
                                marginTop: '1vh',
                                fontSize: '1.2vw',
                              }}
                            >
                              <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                                Customer :
                              </span>{' '}
                              {booking.customer.name}
                            </div>
                            <div
                              style={{
                                color: 'rgb(0, 0, 0, 0.6)',
                                marginTop: '1vh',
                                fontSize: '1.2vw',
                              }}
                            >
                              <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                                Car :
                              </span>{' '}
                              {booking.vehicle.brand_family} ({booking.vehicle.brand})
                            </div>
                            <div
                              style={{
                                color: 'rgb(0, 0, 0, 0.6)',
                                marginTop: '1vh',
                                fontSize: '1.2vw',
                              }}
                            >
                              <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                                Selected Slot :
                              </span>{' '}
                              {booking.selected_slot}
                            </div>
                            <div
                              style={{
                                color: 'rgb(0, 0, 0, 0.6)',
                                marginTop: '1vh',
                                fontSize: '1.2vw',
                              }}
                            >
                              <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                                Service :
                              </span>{' '}
                              {booking.service.service_name} (Cost:{' '}
                              {booking.service.cost})
                            </div>
                            <div
                              style={{
                                color: 'rgb(0, 0, 0, 0.6)',
                                marginTop: '1vh',
                                fontSize: '1.2vw',
                              }}
                            >
                              <span style={{ color: 'rgb(0, 0, 0, 1)' }}>
                                Payment Status :  {booking.payment_status} 
                              </span>
                              
                            </div>
                          </GridItem>
                        ))
                    ) : (
                      <p>No bookings available.</p>
                    )}
                  </GridContainer>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {hiddenClass ? <></> : <Footer />}
    </div>
  )
}

export default PatnerHome
