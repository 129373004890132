import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import arrow from '../Components/Assets/arrow.svg'
import noorders from '../Components/Assets/noorders.svg'
import pleaselogin from '../Components/Assets/pleaselogin.svg'
import { LoadingSpinner } from '../Components/LoadingSpinner '
import { useDevice } from '../Components/Context/deviceContext'

const GridContainer = styled.div`
  display: grid;
  height: auto;
  margin-top: 2%;
  margin-bottom: 6vh;
  grid-template-columns: repeat(2, 1fr);
  gap: 5vh;

  @media (min-width: 501px) and (max-width: 1200px) {
    margin-top: 2vh;
    grid-template-columns: repeat(1, 1fr);
    gap: 2vh;
  }
  @media (max-width: 500px) {
    margin-top: 2vh;
    grid-template-columns: repeat(1, 1fr);
    gap: 2vh;
  }
`

const GridItem = styled.div`
  border: 1.5px solid #faba49;
  cursor: pointer;
  border-radius: 3vw;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2vw;
  @media (min-width: 501px) and (max-width: 1200px) {
    border-radius: 4vw;
    padding: 4vw;
  }

  @media (max-width: 500px) {
    border-radius: 7vw;
    padding: 5vw;
  }
`

const BookingDetails = styled.div`
  font-size: 1.2rem;
  margin: 10px 0;
`

const Heading1 = styled.div`
  font-size: 2vw;
  font-weight: 400;
  @media (min-width: 501px) and (max-width: 1200px) {
    font-size: 3.8vw;
    font-wieght: 600;
    letter-spacing: 0.5vw;
  }
  @media (max-width: 500px) {
    font-size: 4.7vw;
    font-wieght: 700;
    letter-spacing: 0.5vw;
  }
`

const DriverOrders = () => {
  const navigate = useNavigate()
  const [bookings, setBookings] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [isPhonePresent, setIsPhonePresent] = useState(false)
  const { phoneNumber } = useDevice()
  const [hiddenClass, setHiddenClass] = useState('')
  const [hiddenClassmobile, setHiddenClassmobile] = useState('')

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setHiddenClass('hidden')
      } else {
        setHiddenClass('')
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handleResizemobile = () => {
      if (window.innerWidth < 500) {
        setHiddenClassmobile('hidden')
      } else {
        setHiddenClassmobile('')
      }
    }

    window.addEventListener('resize', handleResizemobile)
    handleResizemobile() // Initial check

    return () => {
      window.removeEventListener('resize', handleResizemobile)
    }
  }, [])

  useEffect(() => {
    const fetchDriverOrders = async () => {
      try {
        setLoading(true) // Start loading
        const response = await axios.get(
          `https://www.dsctech.in/api/driver/bookings/accepted/${phoneNumber}/`,
        )
        setBookings(response.data.accepted_bookings)
      } catch (err) {
        console.error('Error fetching bookings:', err)
        setError('Failed to fetch bookings')
      } finally {
        setLoading(false) // Stop loading
      }
    }

    if (phoneNumber) {
      setIsPhonePresent(true)
      fetchDriverOrders() // Call directly inside useEffect
    } else {
      setIsPhonePresent(false)
      setBookings([]) // Clear bookings if no phone number
    }
  }, [phoneNumber])

  const handleBack = () => navigate(-1)

  if (loading) return <LoadingSpinner />

  if (!isPhonePresent) {
    return (
      <div
        style={{
          width: '100%',
          height: hiddenClass ? '100vh' : '80vh',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {hiddenClass ? (
          <div>
            <button
              onClick={handleBack} // Call handleBack on button click
              style={{
                position: 'absolute',
                top: '7vh',
                left: '7%',
                marginTop: '10px',
                padding: '15px',
                borderRadius: '30px',
                border: '1px solid #ffffff',
                backgroundColor: '#FADAA1',
                color: 'black',
                cursor: 'pointer',
                marginRight: '10px',
              }}
            >
              Back
            </button>
          </div>
        ) : (
          <div></div>
        )}
        Please login to view your previous orders.
        <img
          src={pleaselogin}
          alt="Please login"
          style={{ marginTop: '5vh' }}
          height="45%"
        />
      </div>
    )
  }

  if (error) {
    return <div style={styles.center}>{error}</div>
  }

  if (bookings.length === 0) {
    return (
      <div style={styles.center}>
        <div>No accepted bookings.</div>
        <img src={noorders} alt="No orders" height="40%" />
      </div>
    )
  }

  const handleClick = () => {
    navigate(-1) // This goes back to the previous page
  }

  return (
    <div
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      {hiddenClass ? (
        <div style={{ width: '88%', marginLeft: 'auto', marginRight: 'auto' }}>
          <div
            onClick={handleClick}
            style={{
              height: hiddenClassmobile ? '5vh' : '7vh',
              width: hiddenClassmobile ? '5vh' : '7vh',
              borderRadius: '50%',
              backgroundColor: '#FABA49',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              marginTop: '6vh',
            }}
          >
            <img
              src={arrow}
              alt="arrow"
              height="50%"
              style={{
                transform: 'rotate(-180deg)',
              }}
            />
          </div>
          <Heading1 style={{ marginTop: '5vh' }}>Driver Bookings</Heading1>
          <GridContainer>
            {bookings.map((booking) => (
              <GridItem key={booking.id}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                     <div
                      style={{
                        color: 'rgb(0, 0, 0, 1)',
                        fontSize: hiddenClassmobile ? '3vw' : '2vw' ,
                        height: '4.5vh',
                        backgroundColor: '#FADAA1',
                        width: 'auto',
                        paddingLeft: '2.5vw',
                        paddingRight: '2.5vw',
                        borderRadius: '5vw',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      {booking.booking_status}{' '}
                    </div>
                  <div
                    style={{
                      color: 'rgb(0, 0, 0, 0.6)',
                      fontSize: hiddenClassmobile ? '3vw' : '2vw' ,
                    }}
                  >
                    <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Date : </span>{' '}
                    {new Date(booking.booking_date).toLocaleDateString(
                      'en-GB',
                      {
                        day: 'numeric',
                        month: 'short',
                      },
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ fontSize: hiddenClassmobile ? "4.6vw" : '3.4vw',marginTop: '2.5vh' }}>
                    {booking.shop.shop_name}
                  </div>
                  <div
                    style={{
                      color: 'rgb(0, 0, 0, 0.6)',
                      marginTop: '1vh',
                      fontSize: hiddenClassmobile ? '3vw' : '2vw' ,
                    }}
                  >
                    <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Customer :</span>{' '}
                    {booking.customer.name}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      color: 'rgb(0, 0, 0, 0.6)',
                      marginTop: '1vh',
                      fontSize: hiddenClassmobile ? '3vw' : '2vw' ,
                    }}
                  >
                    <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Car :</span>{' '}
                    {booking.vehicle.brand_family }
                  </div>
                  <div
                    style={{
                      color: 'rgb(0, 0, 0, 0.6)',
                      marginTop: '1vh',
                      fontSize: hiddenClassmobile ? '3vw' : '2vw' ,
                    }}
                  >
                    <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Slot :</span>{' '}
                    {booking.selected_slot}
                  </div>
                </div>
              </GridItem>
            ))}
          </GridContainer>
        </div>
      ) : (
        <div style={{width:'90%', marginLeft:"5%", marginRight:'5%'}}>
          <div
            style={{
              fontSize: '1.8vw',
              fontWeight: '500',
              marginTop: '5vh',
            }}
          >
            Driver Bookings
          </div>
          <GridContainer>
            {bookings.map((booking) => (
              <GridItem key={booking.id}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      color: 'rgb(0, 0, 0, 1)',
                      fontSize: '1vw',
                      height: '4.5vh',
                      backgroundColor: '#FADAA1',
                      width: 'auto',
                      paddingLeft: '1.5vw',
                      paddingRight: '1.5vw',
                      borderRadius: '2vw',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                  >
                    {booking.booking_status}{' '}
                  </div>
                  <div
                    style={{
                      color: 'rgb(0, 0, 0, 0.6)',
                      fontSize: '1.2vw',
                    }}
                  >
                    <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Date : </span>{' '}
                    {new Date(booking.booking_date).toLocaleDateString(
                      'en-GB',
                      {
                        day: 'numeric',
                        month: 'short',
                      },
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ fontSize: '1.5vw', marginTop: '2.5vh' }}>
                    {booking.shop.shop_name}
                  </div>
                  <div
                    style={{
                      color: 'rgb(0, 0, 0, 0.6)',
                      marginTop: '1vh',
                      fontSize: '1.2vw',
                    }}
                  >
                    <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Customer :</span>{' '}
                    {booking.customer.name}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      color: 'rgb(0, 0, 0, 0.6)',
                      marginTop: '1vh',
                      fontSize: '1.2vw',
                    }}
                  >
                    <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Car :</span>{' '}
                    {booking.vehicle.brand_family }
                  </div>
                  <div
                    style={{
                      color: 'rgb(0, 0, 0, 0.6)',
                      marginTop: '1vh',
                      fontSize: '1.2vw',
                    }}
                  >
                    <span style={{ color: 'rgb(0, 0, 0, 1)' }}>Slot :</span>{' '}
                    {booking.selected_slot}
                  </div>
                </div>
              </GridItem>
            ))}
          </GridContainer>
        </div>
      )}
    </div>
  )
}

const styles = {
  center: {
    width: '100vw',
    height: '80vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  backButton: {
    margin: '10px',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: '#FABA49',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  arrow: {
    transform: 'rotate(-180deg)',
    height: '50%',
  },
}

export default DriverOrders
