import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { useDevice } from '../Components/Context/deviceContext'
import styled from 'styled-components'
import location1 from '../Components/Assets/Location.svg'
import address from '../Components/Assets/address.svg'
import clock from '../Components/Assets/clock.svg'
import star from '../Components/Assets/star.svg'
import tyre from '../Components/Assets/tyre.png'
import MapComponent from './MapComponent'
import arrow from '../Components/Assets/arrow.svg'
import { LoadingSpinner } from '../Components/LoadingSpinner '

const Heading = styled.div`
  font-size: 2.2vw;
  font-weight: 500;
  margin-left: 5%;
  margin-top: 5vh;
  letter-spacing: 0.08vw;
`

const ButtonGroup = styled.div`
  display: flex;
  gap: 1vw;
  margin-top: 1vh;
  @media (min-width: 768px) and (max-width: 1200px) {
    gap: 3vw;
  }
  @media (max-width: 767px) {
    gap: 3vw;
  }
`

const Button = styled.button`
  padding: 0.5vw 2vw;
  width: 9.5vw;
  font-size: 1.2vw;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#FADAA1' : '#fff')};
  border: 2px solid #faba49;
  border-radius: 2vw;
  color: black;

  &:hover {
    background-color: #fadaa1;
  }
  @media (min-width: 501px) and (max-width: 1200px) {
    padding: 0.5vw 2vw;
    border-radius: 5vw;
    width: 30vw;
    height: 6vh;
    font-size: 3vw;
  }
  @media (max-width: 500px) {
    padding: 1.7vw 2vw;
    border-radius: 7vw;
    width: 30vw;
    font-size: 4vw;
  }
`

const DateInput = styled.input`
  font-size: 1.2vw;
  padding: 0.5vw;
  margin-top: 1vh;
  border-radius: 2vw;
  border: 2px solid #faba49;
  background-color: white;
  cursor: pointer; /* Makes it look clickable */

  /* Disable manual text entry (non-editable) */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* When input is focused, style the border and background */
  &:focus {
    border: 1px solid #faba49;
    background-color: #ffebcc;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 3.1vw;
    padding: 1.7vw 2vw;
    border-radius: 6vw;
  }

  @media (max-width: 767px) {
    font-size: 4.5vw;
    padding: 1.7vw 2vw;
    border-radius: 8vw;
  }
`

const SlectInput = styled.select`
  font-size: 1vw;
  padding: 0.5vw;
  margin-top: 1vh;
  border-radius: 2vw;
  border: 2px solid #faba49;
  background-color: white;

  &:focus {
    border: 1px solid #faba49;
    background-color: #ffebcc;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 3.1vw;
    padding: 1.7vw 2vw;
    border-radius: 4vw;
  }
  @media (max-width: 767px) {
    font-size: 4.5vw;
    padding: 1.7vw 2vw;
    border-radius: 6vw;
  }
`

const Button1 = styled.button`
  position: relative; /* Ensure the button is positioned relative to its container */
  padding: 0.6vw;
  background-color: #faba49;
  color: #000;
  font-size: 1.2vw;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  overflow: hidden; /* Ensure no content overflows the button */
  display: flex; /* Use flex to align text properly */
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover; /* Ensure image covers the button */
    z-index: 1; /* Ensure image is behind the text */
    opacity: 0.1;
  }

  span {
    position: relative;
    z-index: 2; /* Ensure text is above the image */
  }

  @media (min-width: 501px) and (max-width: 1200px) {
    padding: 1.5vw 2vw;
    border-radius: 5vw;
    width: 9.5vw;
    font-size: 3.1vw;
  }
  @media (max-width: 500px) {
    padding: 1.7vw 2vw;
    border-radius: 7vw;
    width: 30vw;
    font-size: 4.5vw;
  }
`

const HideContainer = styled.div`
  @media (max-width: 1200px) {
    display: none;
  }
`

const Input = styled.input`
  margin-bottom: 15px;
  width: auto;
  padding: 1vw;
  border-radius: 3vw;
  background-color: #ffffff;
  border: 1px solid #ff8c00;
  font-size: 1.2vw;

  &:focus {
    background-color: #fff7d4;
    border: 1px solid #ff8c00;
    outline: none;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    width: 88%;
  }

  @media (max-width: 767px) {
    font-size: 14px;
    width: 88%;
    padding: 15px 10px;
    margin-left: auto;
    margin-right: auto;
  }
`

const PaymentDetailsContainer = styled.div`
  background-color: transparent;
  padding: 2vw;
  border-radius: 3vw;
  width: 31vw;
  border: 1px solid #ff8c00;
  @media (min-width: 768px) and (max-width: 1200px) {
    padding: 4vw;
    border-radius: 5vw;
    margin-bottom: 12vh;
    width: 88%;
    margin-top: 4vh;
  }

  @media (max-width: 767px) {
    padding: 5vw;
    border-radius: 8vw;
    width: 88%;
    margin-bottom: 13vh;
    margin-top: 4vh;
  }
`

const PaymentList = styled.div`
  list-style-type: none;
  padding: 0;
  margin-top: 3vh;
  font-family: 'Arial', sans-serif;
`

const PaymentItem = styled.li`
  font-size: 0.9vw;
`

const PaymentTitle = styled.strong`
  font-weight: 400;
  margin-bottom: 1.4vh;
  color: #000000;
  opacity: 70%;
  letter-spacing: 1px;
`

const PaymentValue = styled.span`
  color: #555;
  margin-left: 10px;
`

const CouponSection = styled.div`
  display: flex;
  margin-top: 2vh;
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: 768px) and (max-width: 1200px) {
    margin-top: 0vh;
  }

  @media (max-width: 767px) {
    margin-top: 0vh;
  }
`

const Dialog = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 3vw;
  display: ${(props) => (props.show ? 'block' : 'none')};
`

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const DialogBox = styled.div`
  background: #fff;
  border-radius: 1vw;
  width: 30vw;
  max-height: 60vh;
  overflow-y: scroll;
  padding: 2vw;
  padding-bottom: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  @media (min-width: 501px) and (max-width: 1200px) {
    width: 80vw;
    max-height: 70vh;
    padding-left: 5vw;
    padding: 3vw;
  }
  @media (max-width: 500px) {
    width: 80vw;
    max-height: 80vh;
    padding-left: 5vw;
    padding: 4vw;
  }
`

const CouponList = styled.div`
  list-style: none;
  padding: 0;
`

const CouponItem = styled.li`
  padding: 1vw 2vw;
  cursor: pointer;
  border: 1px solid #ff8c00;
  background-color: transparent;
  margin-bottom: 2vh;
  border-radius: 3vw;
  &:hover {
    background-color: #fff7d4;
  }
`

const CouponInput = styled.input`
  width: 21vw;
  outline: none;
  transition: all 0.3s ease;
  padding: 0.8vw;
  padding-left: 1.5vw;
  border-radius: 3vw;
  background-color: #ffffff;
  border: 1px solid #ff8c00;
  font-size: 1.2vw;

  &:focus {
    background-color: #fff7d4;
    border: 1px solid #ff8c00;
    outline: none;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 3.1vw;
    width: 50vw;
    padding-left: 3.5vw;
    height: 6vh;
    border-radius: 5vw;
  }

  @media (max-width: 767px) {
    font-size: 4vw;
    width: 50vw;
    padding-left: 3.5vw;
    height: 6vh;
    border-radius: 7vw;
  }
`

const ApplyButton = styled.button`
  padding: 0.8vw;
  width: 6vw;
  background-color: #fff7d4;
  color: black;
  border: 1px solid #ff8c00;
  border-radius: 3vw;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1.2vw;
  letter-spacing: 1px;
  &:hover {
    background-color: #ff8c00;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 3.1vw;
    width: 20vw;
    height: 6vh;
    border-radius: 5vw;
  }

  @media (max-width: 767px) {
    font-size: 4vw;
    width: 20vw;
    border-radius: 7vw;
    height: 6vh;
  }
`

const ViewCouponsButton = styled.div`
  background-color: transparent;
  color: #ff8c00;
  cursor: pointer;
  margin-top: 1.2vh;
  font-size: 1vw;
  letter-spacing: 1px;
  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 2.1vw;
  }

  @media (max-width: 767px) {
    font-size: 3vw;
  }
`

const CarWashDetails = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { phoneNumber } = useDevice()
  const [details, setDetails] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loading1, setLoading1] = useState(true)
  const [error, setError] = useState(null)
  const [driverRequired, setDriverRequired] = useState(false)
  const [selectedDate, setSelectedDate] = useState('')
  const [services, setServices] = useState([])
  const [selectedService, setSelectedService] = useState('')
  const [selectedServiceCost, setSelectedServiceCost] = useState('')
  const [hiddenClass, setHiddenClass] = useState('')
  const [hiddenClassmobile, setHiddenClassmobile] = useState('')
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const distance = queryParams.get('distance')
  const price = queryParams.get('price')
  const today = new Date().toISOString().split('T')[0]
  const [availableSlots, setAvailableSlots] = useState([])
  const [bookedSlots, setBookedSlots] = useState([])
  const [selectedSlot, setSelectedSlot] = useState('')
  const [addresses, setAddresses] = useState([]) // State for addresses
  const [selectedAddress, setSelectedAddress] = useState('')
  const [loadingAddresses, setLoadingAddresses] = useState(false)
  const [newAddress, setNewAddress] = useState({
    street: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  })
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedCar, setSelectedCar] = useState('') // State for selected car
  const [loadingCars, setLoadingCars] = useState(false)
  const [slotSucces, setslotSucces] = useState(false)
  const [newCar, setNewCar] = useState({
    model: '',
    color: '',
    carNumber: '',
    carName: '',
    carType: '',
    rcPhoto: null,
    insurancePhoto: null,
  })
  const [paymentDetails, setPaymentDetails] = useState([])
  const [isDialogOpen1, setIsDialogOpen1] = useState(false)
  const [BookingID, setBookingID] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const [coupons, setCoupons] = useState([])
  const [selectedCoupon, setSelectedCoupon] = useState('')
  const [selectedCouponprecent, setSelectedCouponprecent] = useState(null)
  console.log('coupon percentage', selectedCoupon)
  const handleViewCouponsClick = () => {
    setShowDialog(true)
    fetchCoupons()
  }

  const calculatePaymentDetails = () => {
    const item_total = parseFloat(price) || 0 // Ensure price is a number
    const platform_fee = 9.0

    // Calculate coupon discount amount
    const coupon_discount_amount = parseFloat(
      (
        ((item_total + platform_fee) * (selectedCouponprecent || 0)) /
        100
      ).toFixed(2), // Ensure selectedCouponprecent is a number
    )

    // Calculate net amount
    const net_amount = parseFloat(
      (item_total - coupon_discount_amount + platform_fee).toFixed(2),
    )

    // Calculate gross tax
    const gross_tax = parseFloat((net_amount * (18 / 100)).toFixed(2))

    // Calculate grand total
    const grand_total = parseFloat((net_amount + gross_tax).toFixed(2))

    // Prepare payment details
    const paymentDetailsArray = [
      {
        item_total: parseFloat(item_total.toFixed(2)), // Ensure consistent decimal places
        platform_fee: parseFloat(platform_fee.toFixed(2)),
        coupon_discount_amount,
        net_amount,
        gross_tax,
        grand_total,
      },
    ]

    // Set payment details
    setPaymentDetails(paymentDetailsArray)
  }

  React.useEffect(() => {
    calculatePaymentDetails()
  }, [selectedCoupon])

  const fetchCoupons = async () => {
    try {
      const response = await fetch(
        `https://www.dsctech.in/carshops/coupons/phone/${phoneNumber}/`,
      )
      const data = await response.json()

      // Check if the error message is present and prevent logging it
      if (data.error) {
        console.log('Coupon not found.')
      } else {
        console.log('coupondata', data)
        setCoupons(data.available_coupons)
      }
    } catch (error) {
      console.error('Error fetching coupons:', error)
    }
  }

  const handleCouponSelect = (couponCode, percent) => {
    setSelectedCoupon(couponCode)
    setSelectedCouponprecent(percent)
    setShowDialog(false) // Close the dialog after selecting
  }

  // const createBooking = async () => {
  //   if (!selectedSlot) {
  //     alert('Please select a time slot.')
  //     return
  //   }

  //   if (!selectedAddress && driverRequired) {
  //     // Check if address is needed
  //     alert('Please select an address.')
  //     return
  //   }

  //   setLoading(true) // Set loading to true

  //   // Ensure selectedDate is a valid date before formatting
  //   const date = new Date(selectedDate)

  //   if (isNaN(date.getTime())) {
  //     alert('Please select a valid date.')
  //     setLoading(false)
  //     return
  //   }

  //   const serviced = parseInt(localStorage.getItem('serviceId'), 10)
  //   const vehicle = parseInt(localStorage.getItem('lastCarDetailId'), 10)
  //   const shopId = parseInt(id, 10) // Use `id` from `useParams()` and parse it here

  //   if (isNaN(serviced) || isNaN(shopId)) {
  //     alert('Invalid service or shop ID. Please try again.')
  //     navigate(`/`)
  //     setLoading(false)
  //     return
  //   }

  //   const formattedDate = date.toISOString().split('T')[0]

  //   const bookingData = {
  //     user_phone: phoneNumber,
  //     selected_slot: selectedSlot,
  //     booking_date: formattedDate,
  //     shop: shopId,
  //     vehicle: vehicle,
  //     car_service: serviced,
  //     address: selectedAddress, // ID for selected address
  //     needs_driver: driverRequired, // Pass the driver requirement
  //   }

  //   try {
  //     const response = await axios.post(
  //       'https://www.dsctech.in/api/create_booking/',
  //       bookingData,
  //       {
  //         headers: { 'Content-Type': 'application/json' },
  //       },
  //     )

  //     if (response.status === 200) {
  //       console.log('Booking created:', response.data)
  //       const bookingID = parseInt(response.data.booking.id, 10)

  //       setBookingID(bookingID)
  //     } else {
  //       console.error(
  //         'Failed to create booking:',
  //         response.status,
  //         response.data,
  //       )
  //       setError('Failed to create booking. Please try again.')
  //     }
  //   } catch (error) {
  //     console.error('Error creating booking:', error)
  //     setError('An error occurred. Please try again later.')
  //   } finally {
  //     setLoading(false) // Set loading to false after the request
  //   }
  // }

  // Function to open the dialog
  const openDialog1 = () => {
    setIsDialogOpen1(true)
  }

  // Function to close the dialog and reset car details form
  const closeDialog1 = () => {
    setIsDialogOpen1(false)
    setNewCar({
      model: '',
      color: '',
      carNumber: '',
      carName: '',
      carType: '',
      rcPhoto: null,
      insurancePhoto: null,
    })
  }

  // Function to handle input changes (text fields)
  const handleInputChange1 = (e) => {
    const { name, value } = e.target
    setNewCar((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  // Function to handle file uploads (for rcPhoto and insurancePhoto)
  const handleFileChange = (e) => {
    const { name, files } = e.target
    setNewCar((prev) => ({
      ...prev,
      [name]: files[0], // Store the selected file
    }))
  }

  // Function to submit the new car details
  const handleSubmitCar = async () => {
    if (!newCar.model) {
      alert('Please enter the car model.')
      return
    }

    if (!newCar.color) {
      alert('Please enter the car color.')
      return
    }

    if (!newCar.carNumber) {
      alert('Please enter the car number.')
      return
    }

    if (!newCar.carName) {
      alert('Please enter the car name.')
      return
    }

    if (!newCar.carType) {
      alert('Please enter the car type.')
      return
    }

    // Use FormData for file uploads
    const formData = new FormData()

    // Append fields to formData
    formData.append('phone', phoneNumber)
    formData.append('model', newCar.model)
    formData.append('color', newCar.color)
    formData.append('car_number', newCar.carNumber)
    formData.append('car_name', newCar.carName)
    formData.append('car_type', newCar.carType)
    if (newCar.rcPhoto) formData.append('rcPhoto', newCar.rcPhoto)
    if (newCar.insurancePhoto)
      formData.append('insurancePhoto', newCar.insurancePhoto)

    try {
      const response = await axios.post(
        'https://www.dsctech.in/api/add_car_details/',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', // Set the proper headers for file upload
          },
        },
      )

      console.log('Car details added:', response.data)
      closeDialog1()
    } catch (error) {
      setError(
        `Error adding car details: ${
          error.response ? error.response.data : error.message
        }`,
      )
      console.error('Error:', error)
    }
  }

  const openDialog = () => {
    setIsDialogOpen(true)
  }

  // Function to close the dialog
  const closeDialog = () => {
    setIsDialogOpen(false)
    // Reset new address fields if needed
    setNewAddress({
      street: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    })
  }

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setNewAddress((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  // Function to submit the new address
  const handleSubmitAddress = async () => {
    // Check for missing required fields
    if (!newAddress.street) {
      alert('Please enter your street address.')
      return
    }

    if (!newAddress.city) {
      alert('Please enter your city.')
      return
    }

    if (!newAddress.state) {
      alert('Please enter your state.')
      return
    }

    if (!newAddress.postal_code) {
      alert('Please enter your postal code.')
      return
    }

    if (!newAddress.country) {
      alert('Please enter your country.')
      return
    }

    try {
      const response = await axios.post(
        'https://www.dsctech.in/add-address/',
        {
          phone: phoneNumber,
          street: newAddress.street,
          city: newAddress.city,
          state: newAddress.state,
          postal_code: newAddress.postal_code,
          country: newAddress.country,
        },
        {
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
          },
        },
      )

      console.log('Address added:', response.data)
      // Optionally fetch addresses
      fetchAddresses() // Make sure this function is defined

      // Close dialog
      closeDialog()
    } catch (error) {
      console.error('Error adding address:', error)
      // Handle error state if needed
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setHiddenClass('hidden')
      } else {
        setHiddenClass('')
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handleResizemobile = () => {
      if (window.innerWidth < 500) {
        setHiddenClassmobile('hidden')
      } else {
        setHiddenClassmobile('')
      }
    }

    window.addEventListener('resize', handleResizemobile)
    handleResizemobile() // Initial check

    return () => {
      window.removeEventListener('resize', handleResizemobile)
    }
  }, [])

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true)
      setError(null)

      if (!id || !phoneNumber) {
        setError('ID or Phone Number is missing')
        setLoading(false)
        return
      }

      try {
        const url = `https://www.dsctech.in/carshops/${id}`
        const response = await axios.get(url)

        if (response.data) {
          const shopDetails = response.data
          setDetails(shopDetails)
          setServices(shopDetails.services || []) // Set services from the API response
        } else {
          setError('No data found')
        }
      } catch (error) {
        setError(`Error fetching car shop details: ${error.message}`)
        console.error('Error:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchDetails()
  }, [id, phoneNumber])

  const handleBooking = async () => {
    setLoading1(true)

    if (!selectedDate) {
      alert('Please select a date.')
      setLoading1(false)
      return
    }

    const serviced = parseInt(localStorage.getItem('serviceId'), 10)
    const shopId = parseInt(id, 10) // Use `id` from `useParams()` and parse it here

    if (isNaN(serviced) || isNaN(shopId)) {
      alert('Invalid service or shop ID. Please try again.')
      navigate(`/`)
      setLoading1(false)
      return
    }

    const formattedDate = new Date(selectedDate).toISOString().split('T')[0]

    const params = {
      shop: shopId,
      car_service: serviced,
      booking_date: formattedDate,
    }

    try {
      const response = await axios.post(
        'https://www.dsctech.in/api/fetch_available_slots/',
        params,
      )
      const fetchedSlots = response.data.available_slots
      const bookedslot = response.data.booked_slots
      setAvailableSlots(fetchedSlots)
      setBookedSlots(bookedslot || [])
      alert(`Please Slect Slot`)
      // Fetch other details
      fetchAddresses()
      setslotSucces(true)
    } catch (error) {
      alert(`Error fetching available slots`)
      console.error('Error:', error)
    } finally {
      setLoading1(false)
    }
  }

  // Fetch addresses from the API
  const fetchAddresses = async () => {
    setLoadingAddresses(true)

    try {
      const response = await axios.get(
        `https://www.dsctech.in/addresses/${phoneNumber}/`,
      )
      console.log('Fetched Addresses:', response.data.addresses) // Log the addresses data

      if (
        Array.isArray(response.data.addresses) &&
        response.data.addresses.length > 0
      ) {
        const addressesList = response.data.addresses
        setAddresses(addressesList) // Set the fetched addresses from the 'addresses' key
        setSelectedAddress(addressesList[addressesList.length - 1].id) // Automatically select the last address
      } else {
        setAddresses([])
        setSelectedAddress('') // Reset selectedAddress if no addresses are found
      }
    } catch (error) {
      setError(
        `Error fetching addresses: ${
          error.response ? error.response.data : error.message
        }`,
      )
      console.error('Error:', error)
    } finally {
      setLoadingAddresses(false)
    }
  }

  if (loading) return <LoadingSpinner />
  if (!details) return <div>No details available</div>

  const handleClick = () => {
    navigate(-1)
  }

  const handleFocus = (e) => {
    e.target.showPicker()
  }

  const createBooking = async () => {
    if (!selectedSlot) {
      alert('Please select a time slot.')
      return
    }

    if (!selectedAddress && driverRequired) {
      // Check if address is needed
      alert('Please select an address.')
      return
    }

    setLoading(true) // Set loading to true

    // Ensure selectedDate is a valid date before formatting
    const date = new Date(selectedDate)

    if (isNaN(date.getTime())) {
      alert('Please select a valid date.')
      setLoading(false)
      return
    }
    const serviced = parseInt(localStorage.getItem('serviceId'), 10)
    const vehicle = parseInt(localStorage.getItem('lastCarDetailId'), 10)
    const shopId = parseInt(id, 10) // Use `id` from `useParams()` and parse it here

    if (isNaN(serviced) || isNaN(shopId)) {
      alert('Invalid service or shop ID. Please try again.')
      navigate(`/`)
      setLoading1(false)
      return
    }

    const formattedDate = date.toISOString().split('T')[0]

    let bookingData

    if (selectedCoupon === '') {
      bookingData = {
        user_phone: phoneNumber,
        selected_slot: selectedSlot,
        booking_date: formattedDate,
        shop: shopId,
        vehicle: vehicle,
        car_service: serviced,
        address: selectedAddress,
        needs_driver: driverRequired,
      }
    } else {
      bookingData = {
        user_phone: phoneNumber,
        selected_slot: selectedSlot,
        booking_date: formattedDate,
        shop: shopId,
        vehicle: vehicle,
        car_service: serviced,
        address: selectedAddress,
        needs_driver: driverRequired,
        coupon_code: selectedCoupon,
      }
    }

    console.log(bookingData)

    try {
      const response = await axios.post(
        'https://www.dsctech.in/api/create_booking/',
        bookingData,
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )

      if (response.status === 200) {
        console.log('Booking created:', response.data)
        const paymentData = {
          BookingID: parseInt(response.data.booking.id, 10),
        }

        const paymentResponse = await fetch(
          'https://www.dsctech.in/api/initiate-payment/',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(paymentData),
          },
        )

        const paymentResult = await paymentResponse.json()

        // Access the correct path for merchantTransactionId
        const merchantTransactionId =
          paymentResult.payment_response.data.merchantTransactionId

        // Store it in localStorage
        localStorage.setItem('merchantTransactionId', merchantTransactionId)

        // Check success status from payment_response
        if (paymentResult.payment_response.success) {
          setLoading(false)
          // Redirect to payment URL if successful
          window.location.href =
            paymentResult.payment_response.data.instrumentResponse.redirectInfo.url
        } else {
          console.error(
            'Payment initiation failed:',
            paymentResult.payment_response.message,
          )
          setError('Failed to initiate payment. Please try again.')
        }
      } else {
        console.error(
          'Failed to create booking:',
          response.status,
          response.data,
        )
        setError('Failed to create booking. Please try again.')
      }
    } catch (error) {
      console.error('Error creating booking:', error)
      setError('An error occurred. Please try again later.')
    } finally {
      setLoading(false) // Set loading to false after the request
    }
  }

  const createBooking1 = async () => {
    setLoading(true) // Start loading
    try {
      // Prepare payment data
      const paymentData = {
        BookingID: parseInt(BookingID, 10),
      }

      // Make the API call
      const paymentResponse = await fetch(
        'https://www.dsctech.in/api/initiate-payment/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(paymentData),
        },
      )

      // Parse the response
      const paymentResult = await paymentResponse.json()

      // Check if the response contains the required payment data
      const paymentResponseData = paymentResult?.payment_response?.data

      if (paymentResponseData?.merchantTransactionId) {
        const {
          merchantTransactionId,
          instrumentResponse,
        } = paymentResponseData

        // Store the transaction ID in localStorage
        localStorage.setItem('merchantTransactionId', merchantTransactionId)

        // Check success status from payment_response
        if (paymentResult.payment_response.success) {
          // Redirect to payment URL if successful
          const redirectUrl = instrumentResponse?.redirectInfo?.url

          if (redirectUrl) {
            window.location.href = redirectUrl
          } else {
            console.error('Redirect URL is missing.')
            setError('Payment redirection failed. Please try again.')
          }
        } else {
          console.error(
            'Payment initiation failed:',
            paymentResult.payment_response.message,
          )
          setError('Failed to initiate payment. Please try again.')
        }
      } else {
        console.error(
          'Payment initiation failed:',
          paymentResult.payment_response.message,
        )
        setError('Failed to initiate payment. Please try again.')
      }
    } catch (error) {
      console.error('Error initiating payment:', error)
      setError(
        'An error occurred while processing the payment. Please try again.',
      )
    } finally {
      setLoading(false) // Stop loading
    }
  }

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value)
    setslotSucces(false) // Set slotSuccess to false when date is changed
  }

  const handleServiceChange = (e) => {
    const selectedId = e.target.value
    setSelectedService(selectedId)

    // Find the selected service and set its cost
    const service = services.find(
      (service) => String(service.id) === String(selectedId),
    )

    if (service) {
      setSelectedServiceCost(service.cost)
    } else {
      setSelectedServiceCost('')
      console.log('No service found for selected ID')
    }
    setslotSucces(false)
  }

  return (
    <div
      style={{ minHeight: '85vh', display: 'flex', flexDirection: 'column' }}
    >
      {hiddenClass ? (
        <div style={{ width: '88%', marginLeft: 'auto', marginRight: 'auto' }}>
          <div
            onClick={handleClick}
            style={{
              height: hiddenClassmobile ? '5vh' : '7vh',
              width: hiddenClassmobile ? '5vh' : '7vh',
              borderRadius: '50%',
              backgroundColor: '#FABA49',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              marginTop: '6vh',
            }}
          >
            <img
              src={arrow}
              alt="arrow"
              height="50%"
              style={{
                transform: 'rotate(-180deg)',
              }}
            />
          </div>
          <div style={{ marginTop: '4vh' }}>
            <div
              style={{
                height: '24vh',
                width: '24vh',
                borderRadius: '6vw',
                backgroundColor: 'transparent',
                overflow: 'hidden',
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #FABA49',
              }}
            >
              <img
                src={`https://www.dsctech.in${details.upload_carshop_image}`}
                alt="Car Wash Shop"
                height="85%"
                width="85%"
                style={{ objectFit: 'cover', borderRadius: '4vw' }}
              />
            </div>
          </div>
          {!slotSucces ? (
            <div>
              <div style={{ marginTop: '3vh' }}>
                <div style={{ fontSize: hiddenClassmobile ? '4vw' : '3.1vw' }}>
                  Need Driver?
                </div>
                <ButtonGroup>
                  <Button
                    selected={driverRequired === true}
                    onClick={() => setDriverRequired(true)}
                  >
                    Yes
                  </Button>
                  <Button
                    selected={driverRequired === false}
                    onClick={() => setDriverRequired(false)}
                  >
                    No
                  </Button>
                </ButtonGroup>
              </div>
              <div
                style={{
                  marginTop: '3vh',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <label
                  htmlFor="date-select"
                  style={{ fontSize: hiddenClassmobile ? '4vw' : '3.1vw' }}
                >
                  Select Date
                </label>
                <DateInput
                  type="date"
                  id="date-select"
                  min={today}
                  value={selectedDate}
                  onChange={handleDateChange}
                  onFocus={(e) => e.target.showPicker()}
                />
              </div>
            </div>
          ) : (
            <div>
              <div style={{ marginTop: '3vh' }}></div>
              <div
                style={{
                  fontSize: hiddenClassmobile ? '4vw' : '3.1vw',
                  color: 'rgba(0, 0, 0, 0.6)',
                }}
              >
                Select Slot
              </div>
              <SlectInput
                id="slot-select"
                value={selectedSlot}
                onChange={(e) => setSelectedSlot(e.target.value)}
                style={{
                  width: '88vw',
                  textAlign: 'center',
                  marginTop: '1vh',
                  height: '6vh',
                }}
                disabled={!availableSlots.length} // Disable if no slots are available
              >
                <option value="">Select an available slot</option>
                {availableSlots.map((slot, index) => (
                  <option key={index} value={slot}>
                    {slot}
                  </option>
                ))}
                {bookedSlots.map((slot, index) => (
                  <option key={index} disabled>
                    {slot}
                  </option>
                ))}
              </SlectInput>
              {driverRequired && (
                <div>
                  <div
                    style={{
                      fontSize: hiddenClassmobile ? '4vw' : '3.1vw',
                      color: 'rgba(0, 0, 0, 0.6)',
                      marginTop: '3vh',
                    }}
                  >
                    Address
                  </div>
                  <SlectInput
                    id="address-select"
                    value={selectedAddress}
                    onChange={(e) => setSelectedAddress(e.target.value)}
                    style={{
                      width: '88vw',
                      textAlign: 'center',
                      marginTop: '1vh',
                    }}
                    disabled={loadingAddresses || !addresses.length} // Disable if loading addresses or none are available
                  >
                    <option value="">Select an address</option>
                    {addresses.map((address, index) => (
                      <option key={index} value={address.id}>
                        {`${address.street}, ${address.city}, ${address.state}`}{' '}
                        {/* Display relevant address fields */}
                      </option>
                    ))}
                  </SlectInput>
                  <div style={{ marginTop: '1vh' }}></div>
                  <Button
                    onClick={openDialog}
                    style={{
                      height: '5vh',
                      padding: '5px',
                      borderRadius: '20px',
                      backgroundColor: '#FADAA1',
                      color: 'black',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: hiddenClassmobile ? '4vw' : '3.1vw',
                    }}
                  >
                    Add Address
                  </Button>
                  <div style={{ marginTop: '3vh' }}></div>
                </div>
              )}
              <div style={{ marginTop: '3vh' }}></div>
              {isDialogOpen && (
                <>
                  {/* Overlay with blur effect */}
                  <div
                    style={{
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                      backdropFilter: 'blur(5px)', // Adjust the blur intensity
                      zIndex: 1, // Ensure overlay is above other content
                    }}
                  />

                  {/* Dialog Container */}
                  <div
                    style={{
                      position: 'fixed',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      backgroundColor: '#ffffff',
                      padding: '20px',
                      borderRadius: '10px',
                      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                      zIndex: 2, // Ensure dialog is above the overlay
                      width: '80%', // Set a width for the dialog
                    }}
                  >
                    <h4>Add New Address</h4>

                    {/* Container for Input Fields */}
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'start',
                        width: '100%',
                        gap: '5%',
                      }}
                    >
                      <Input
                        type="text"
                        placeholder="Street"
                        name="street"
                        value={newAddress.street}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="City"
                        name="city"
                        value={newAddress.city}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="State"
                        name="state"
                        value={newAddress.state}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="Postal Code"
                        name="postal_code"
                        value={newAddress.postal_code}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="Country"
                        name="country"
                        value={newAddress.country}
                        onChange={handleInputChange}
                      />
                    </div>

                    {/* Submit and Cancel Buttons */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        onClick={handleSubmitAddress}
                        style={{
                          marginTop: '10px',
                          padding: '10px',
                          borderRadius: '20px',
                          backgroundColor: '#FADAA1',
                          color: 'black',
                          cursor: 'pointer',
                          marginRight: '10px', // Space between buttons
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={closeDialog}
                        style={{
                          marginTop: '10px',
                          padding: '10px',
                          borderRadius: '20px',
                          backgroundColor: '#ffffff',
                          color: 'black',
                          cursor: 'pointer',
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {price > 0 ? (
            <PaymentDetailsContainer>
              <CouponSection>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: 'vh',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <CouponInput
                    type="text"
                    placeholder="Enter Coupon Code"
                    value={selectedCoupon}
                    onChange={(e) => setSelectedCoupon(e.target.value)}
                  />
                  <ApplyButton>Apply</ApplyButton>
                </div>
                <ViewCouponsButton onClick={handleViewCouponsClick}>
                  View Available Coupons
                </ViewCouponsButton>

                {/* Dialog to select a coupon */}
                <DialogOverlay show={showDialog}>
                  <DialogBox>
                    <div
                      style={{
                        fontSize: hiddenClassmobile ? '4vw' : '3.1vw',
                        marginBottom: '2vh',
                      }}
                    >
                      Select a Coupon
                    </div>
                    {coupons.length > 0 && (
                      <CouponList>
                        {coupons.map((coupon) => (
                          <CouponItem
                            key={coupon.code}
                            onClick={() =>
                              handleCouponSelect(
                                coupon.code,
                                coupon.discount_percentage,
                              )
                            }
                          >
                            {coupon.code} ({coupon.discount_percentage}% OFF)
                          </CouponItem>
                        ))}
                      </CouponList>
                    )}
                    {coupons.length === 0 && (
                      <div
                        style={{
                          fontSize: '1.2vw',
                          marginBottom: '2vh',
                        }}
                      >
                        Sorry, coupons are not available.
                      </div>
                    )}
                    {/* ApplyButton is moved into a sticky container */}
                    <div
                      style={{
                        position: 'sticky',
                        bottom: 0,
                        backgroundColor: 'white',
                        padding: '2vh',

                        textAlign: 'center',
                        borderTop: '1px solid #ccc',
                      }}
                    >
                      <ApplyButton onClick={() => setShowDialog(false)}>
                        Close
                      </ApplyButton>
                    </div>
                  </DialogBox>
                </DialogOverlay>
              </CouponSection>
              <PaymentList>
                {paymentDetails.map((detail, index) => (
                  <PaymentItem key={index}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <PaymentTitle
                        style={{
                          fontSize: hiddenClassmobile ? '4vw' : '3.1vw',
                          fontWeight: '500',
                          opacity: '100%',
                        }}
                      >
                        Item Total:
                      </PaymentTitle>
                      <PaymentValue
                        style={{
                          fontSize: hiddenClassmobile ? '4vw' : '3.1vw',
                          color: '#000',
                        }}
                      >
                        ₹{detail.item_total}
                      </PaymentValue>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <PaymentTitle
                        style={{
                          fontSize: hiddenClassmobile ? '3vw' : '2.1vw',
                        }}
                      >
                        Coupon Discount{' '}
                        {selectedCoupon !== '' && (
                          <span style={{ color: 'green',fontSize: hiddenClassmobile ? '2vw' : '2vw', }}>
                            ({selectedCoupon})
                          </span>
                        )}
                        :
                      </PaymentTitle>
                      <PaymentValue
                        style={{
                          fontSize: hiddenClassmobile ? '3vw' : '2.1vw',
                        }}
                      >
                        {selectedCoupon !== '' ? (
                          <span style={{ color: 'green' }}>
                            - ₹{detail.coupon_discount_amount}
                          </span>
                        ) : (
                          <PaymentValue  style={{
                            fontSize: hiddenClassmobile ? '3vw' : '2.1vw',
                          }} >
                            - ₹{detail.coupon_discount_amount}
                          </PaymentValue>
                        )}
                      </PaymentValue>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <PaymentTitle
                        style={{
                          fontSize: hiddenClassmobile ? '3vw' : '2.1vw',
                        }}
                      >
                        Platform Fee:
                      </PaymentTitle>
                      <PaymentValue
                        style={{
                          fontSize: hiddenClassmobile ? '3vw' : '2.1vw',
                        }}
                      >
                        ₹{detail.platform_fee}
                      </PaymentValue>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <PaymentTitle
                        style={{
                          fontSize: hiddenClassmobile ? '3vw' : '2.1vw',
                        }}
                      >
                        Net Amount:
                      </PaymentTitle>
                      <PaymentValue
                        style={{
                          fontSize: hiddenClassmobile ? '3vw' : '2.1vw',
                        }}
                      >
                        ₹{detail.net_amount}
                      </PaymentValue>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <PaymentTitle
                        style={{
                          fontSize: hiddenClassmobile ? '3vw' : '2.1vw',
                        }}
                      >
                        Gross Tax:
                      </PaymentTitle>
                      <PaymentValue
                        style={{
                          fontSize: hiddenClassmobile ? '3vw' : '2.1vw',
                        }}
                      >
                        ₹{detail.gross_tax}
                      </PaymentValue>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <PaymentTitle
                        style={{
                          fontSize: hiddenClassmobile ? '4vw' : '3.1vw',
                          fontWeight: '500',
                          opacity: '100%',
                        }}
                      >
                        Grand Total:
                      </PaymentTitle>
                      <PaymentValue
                        style={{
                          fontSize: hiddenClassmobile ? '4vw' : '3.1vw',
                          color: '#000',
                        }}
                      >
                        ₹{detail.grand_total}
                      </PaymentValue>
                    </div>
                  </PaymentItem>
                ))}
              </PaymentList>
            </PaymentDetailsContainer>
          ) : (
            <></>
          )}

          {!slotSucces ? (
            <div
              style={{
                position: 'fixed',
                bottom: '4vh',
                left: 'auto',
                width: '88%',
                marginTop: '3vh',
                display: 'flex',
                borderRadius: '7vh',
                justifyContent: 'center', // Center the button horizontally
                backgroundColor: 'white', // Optional: to ensure the button is visible against the page content
              }}
            >
              <Button1
                type="button"
                style={{ width: '100%', textAlign: 'center' }}
                onClick={handleBooking}
              >
                <img src={tyre} alt="Tyre" />
                <span>Select Slot</span>
              </Button1>
            </div>
          ) : (
            <div
              style={{
                position: 'fixed',
                bottom: '4vh',
                left: 'auto',
                width: '88%',
                marginTop: '3vh',
                display: 'flex',
                borderRadius: '7vh',
                justifyContent: 'center', // Center the button horizontally
                backgroundColor: 'white', // Optional: to ensure the button is visible against the page content
              }}
            >
              <Button1
                type="button"
                style={{ width: '100%', textAlign: 'center' }}
                onClick={createBooking}
              >
                <img src={tyre} alt="Tyre" />
                <span>Pay Now</span>
              </Button1>
            </div>
          )}
        </div>
      ) : (
        <HideContainer>
          <div style={{ height: '8vh' }} />
          <div style={{ display: 'flex', justifyContent: 'start' }}>
            <div
              style={{
                minHeight: '65vh',
                width: '55vh',
                borderRadius: '3vw',
                marginLeft: '5%',
                border: '1px solid #FABA49',
              }}
            >
              <div
                style={{
                  height: '59vh',
                  width: '49vh',
                  borderRadius: '1.5vw',
                  backgroundColor: 'transparent',
                  overflow: 'hidden',
                  marginLeft: '3vh',
                  marginTop: '3vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={`https://www.dsctech.in${details.upload_carshop_image}`}
                  alt="Car Wash Shop"
                  height="100%"
                  width="100%"
                  style={{ objectFit: 'cover', borderRadius: '2.5vw' }}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                marginLeft: '5%',
              }}
            >
              <div
                style={{
                  fontSize: '1.6vw',
                  letterSpacing: '0.2vw',
                  fontWeight: '500',
                }}
              >
                {details.shop_name || 'Shop Name Not Available'}
              </div>
              {/* Driver Selection */}
              <div style={{ marginTop: '2vh' }}>
                <div style={{ fontSize: '1.2vw', color: 'rgba(0, 0, 0, 0.6)' }}>
                  Need Driver ?
                </div>
                <ButtonGroup>
                  <Button
                    selected={driverRequired === true}
                    onClick={() => setDriverRequired(true)}
                  >
                    Yes
                  </Button>
                  <Button
                    selected={driverRequired === false}
                    onClick={() => setDriverRequired(false)}
                  >
                    No
                  </Button>
                </ButtonGroup>
              </div>
              {/* Date and Time Selection */}
              <div
                style={{
                  marginTop: '2vh',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  htmlFor="date-select"
                  style={{ fontSize: '1.2vw', color: 'rgba(0, 0, 0, 0.6)' }}
                >
                  Select Date
                </div>
                <DateInput
                  type="date"
                  id="date-select"
                  min={today}
                  value={selectedDate}
                  onChange={handleDateChange}
                  style={{ width: '19vw', textAlign: 'center' }}
                  onFocus={(e) => e.target.showPicker()} // Opens the date picker on focus
                />
                <div style={{ marginTop: '3vh', display: 'flex', gap: '1vw' }}>
                  {!slotSucces ? (
                    <Button1
                      type="button"
                      style={{ width: '20vw', textAlign: 'center' }}
                      onClick={handleBooking}
                    >
                      <img src={tyre} alt="Tyre" />
                      <span>Select Slot</span>
                    </Button1>
                  ) : null}
                </div>
                {availableSlots.length > 0 && slotSucces && (
                  <div>
                    <div
                      style={{ fontSize: '1.2vw', color: 'rgba(0, 0, 0, 0.6)' }}
                    >
                      Select Slot
                    </div>
                    <SlectInput
                      id="slot-select"
                      value={selectedSlot}
                      onChange={(e) => setSelectedSlot(e.target.value)}
                      style={{
                        width: '20vw',
                        textAlign: 'center',
                        marginTop: '1vh',
                        height: '6vh',
                      }}
                      disabled={!availableSlots.length} // Disable if no slots are available
                    >
                      <option value="">Select an available slot</option>
                      {availableSlots.map((slot, index) => (
                        <option key={index} value={slot}>
                          {slot}
                        </option>
                      ))}
                      {bookedSlots.map((slot, index) => (
                        <option key={index} disabled>
                          {slot}
                        </option>
                      ))}
                    </SlectInput>
                    <div style={{ marginTop: '2vh' }}></div>
                    {driverRequired && (
                      <div>
                        <div
                          style={{
                            fontSize: '1.2vw',
                            color: 'rgba(0, 0, 0, 0.6)',
                          }}
                        >
                          Address
                        </div>
                        <SlectInput
                          id="address-select"
                          value={selectedAddress}
                          onChange={(e) => setSelectedAddress(e.target.value)}
                          style={{
                            width: '20vw',
                            textAlign: 'center',
                            marginTop: '1vh',
                          }}
                          disabled={loadingAddresses || !addresses.length} // Disable if loading addresses or none are available
                        >
                          <option value="">Select an address</option>
                          {addresses.map((address, index) => (
                            <option key={index} value={address.id}>
                              {`${address.street}, ${address.city}, ${address.state}`}{' '}
                              {/* Display relevant address fields */}
                            </option>
                          ))}
                        </SlectInput>
                        <div style={{ marginTop: '1vh' }}></div>
                        <Button
                          onClick={openDialog}
                          style={{
                            height: '5vh',
                            padding: '5px',
                            borderRadius: '20px',
                            backgroundColor: '#FADAA1',
                            color: 'black',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '14px',
                          }}
                        >
                          Add Address
                        </Button>
                      </div>
                    )}
                    <div style={{ marginTop: '1.5vh' }}></div>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: '5%',
                marginLeft: '5%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: '1vw',
                  flexDirection: 'column',
                }}
              >
                <>
                  {price > 0 ? (
                    <PaymentDetailsContainer>
                      <CouponSection>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            height: '6vh',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <CouponInput
                            type="text"
                            placeholder="Enter Coupon Code"
                            value={selectedCoupon}
                            onChange={(e) => setSelectedCoupon(e.target.value)}
                          />
                          <ApplyButton>Apply</ApplyButton>
                        </div>
                        <ViewCouponsButton onClick={handleViewCouponsClick}>
                          View Available Coupons
                        </ViewCouponsButton>

                        {/* Dialog to select a coupon */}
                        <DialogOverlay show={showDialog}>
                          <DialogBox>
                            <div
                              style={{ fontSize: '1.4vw', marginBottom: '3vh' }}
                            >
                              Select a Coupon
                            </div>
                            {coupons.length > 0 && (
                              <CouponList>
                                {coupons.map((coupon) => (
                                  <CouponItem
                                    key={coupon.code}
                                    onClick={() =>
                                      handleCouponSelect(
                                        coupon.code,
                                        coupon.discount_percentage,
                                      )
                                    }
                                  >
                                    {coupon.code} ({coupon.discount_percentage}%
                                    OFF)
                                  </CouponItem>
                                ))}
                              </CouponList>
                            )}
                            {coupons.length === 0 && (
                              <div
                                style={{
                                  fontSize: '1.2vw',
                                  marginBottom: '2vh',
                                }}
                              >
                                Sorry, coupons are not available.
                              </div>
                            )}
                            {/* ApplyButton is moved into a sticky container */}
                            <div
                              style={{
                                position: 'sticky',
                                bottom: 0,
                                backgroundColor: 'white',
                                padding: '2vh',

                                textAlign: 'center',
                                borderTop: '1px solid #ccc',
                              }}
                            >
                              <ApplyButton onClick={() => setShowDialog(false)}>
                                Close
                              </ApplyButton>
                            </div>
                          </DialogBox>
                        </DialogOverlay>
                      </CouponSection>
                      <PaymentList>
                        {paymentDetails.map((detail, index) => (
                          <PaymentItem key={index}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <PaymentTitle
                                style={{
                                  fontSize: '1.2vw',
                                  fontWeight: '500',
                                  opacity: '100%',
                                }}
                              >
                                Item Total:
                              </PaymentTitle>
                              <PaymentValue
                                style={{ fontSize: '1.2vw', color: '#000' }}
                              >
                                ₹{detail.item_total}
                              </PaymentValue>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <PaymentTitle>
                                Coupon Discount{' '}
                                {selectedCoupon !== '' && (
                                  <span style={{ color: 'green' }}>
                                    ({selectedCoupon})
                                  </span>
                                )}
                                :
                              </PaymentTitle>
                              <PaymentValue>
                                {selectedCoupon !== '' ? (
                                  <span style={{ color: 'green' }}>
                                    - ₹{detail.coupon_discount_amount}
                                  </span>
                                ) : (
                                  <PaymentValue>
                                    - ₹{detail.coupon_discount_amount}
                                  </PaymentValue>
                                )}
                              </PaymentValue>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <PaymentTitle>Platform Fee:</PaymentTitle>
                              <PaymentValue>
                                ₹{detail.platform_fee}
                              </PaymentValue>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <PaymentTitle>Net Amount:</PaymentTitle>
                              <PaymentValue>₹{detail.net_amount}</PaymentValue>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <PaymentTitle>Gross Tax:</PaymentTitle>
                              <PaymentValue>₹{detail.gross_tax}</PaymentValue>
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <PaymentTitle
                                style={{
                                  fontSize: '1.2vw',
                                  fontWeight: '500',
                                  opacity: '100%',
                                }}
                              >
                                Grand Total:
                              </PaymentTitle>
                              <PaymentValue
                                style={{ fontSize: '1.2vw', color: '#000' }}
                              >
                                ₹{detail.grand_total}
                              </PaymentValue>
                            </div>
                          </PaymentItem>
                        ))}
                      </PaymentList>
                    </PaymentDetailsContainer>
                  ) : (
                    <></>
                  )}
                </>
                {selectedSlot !== '' ? (
                  <Button1
                    type="button"
                    style={{ width: '35vw', textAlign: 'center' }}
                    onClick={createBooking}
                  >
                    <img src={tyre} alt="Tyre" />
                    <span>Pay Now</span>
                  </Button1>
                ) : null}
              </div>
              {isDialogOpen && (
                <>
                  {/* Overlay with blur effect */}
                  <div
                    style={{
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                      backdropFilter: 'blur(5px)', // Adjust the blur intensity
                      zIndex: 1, // Ensure overlay is above other content
                    }}
                  />

                  {/* Dialog Container */}
                  <div
                    style={{
                      position: 'fixed',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      backgroundColor: '#ffffff',
                      padding: '20px',
                      borderRadius: '10px',
                      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                      zIndex: 2, // Ensure dialog is above the overlay
                      width: '500px', // Set a width for the dialog
                    }}
                  >
                    <h4>Add New Address</h4>

                    {/* Container for Input Fields */}
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'start',
                        width: '100%',
                        gap: '5%',
                      }}
                    >
                      <Input
                        type="text"
                        placeholder="Street"
                        name="street"
                        value={newAddress.street}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="City"
                        name="city"
                        value={newAddress.city}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="State"
                        name="state"
                        value={newAddress.state}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="Postal Code"
                        name="postal_code"
                        value={newAddress.postal_code}
                        onChange={handleInputChange}
                      />
                      <Input
                        type="text"
                        placeholder="Country"
                        name="country"
                        value={newAddress.country}
                        onChange={handleInputChange}
                      />
                    </div>

                    {/* Submit and Cancel Buttons */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        onClick={handleSubmitAddress}
                        style={{
                          marginTop: '10px',
                          padding: '10px',
                          borderRadius: '20px',
                          backgroundColor: '#FADAA1',
                          color: 'black',
                          cursor: 'pointer',
                          marginRight: '10px', // Space between buttons
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={closeDialog}
                        style={{
                          marginTop: '10px',
                          padding: '10px',
                          borderRadius: '20px',
                          backgroundColor: '#ffffff',
                          color: 'black',
                          cursor: 'pointer',
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </HideContainer>
      )}
      {/* <MapComponent origin={origin} destination={destination} /> */}
    </div>
  )
}

export default CarWashDetails
