import React, { useEffect, useRef, useState } from 'react'
import { getDeviceType } from '../../Utils/deviceUtils'
import herocontainer from '../../Components/Assets/herocontainer.png'
import hero from '../../Components/Assets/hero.png'
import arrow from '../../Components/Assets/arrow.svg'
import styled from 'styled-components'
import { ReactComponent as SearchIconSVG } from '../../Components/Assets/Search.svg'
import CryptoJS from 'crypto-js'
import { useNavigate } from 'react-router-dom'
import { useDevice } from '../../Components/Context/deviceContext'
import '../../Components/Navbar/Navbar.css'
import profile from '../../Components/Assets/profile.svg'
import LazyLoad from 'react-lazyload'
import atlocation from '../../Components/Assets/atlocation.svg'
import Footer from '../../Components/fotter'
import Delete from '../../Components/Assets/delete.svg'
import axios from 'axios'
import { LoadingSpinner } from '../../Components/LoadingSpinner '

// Styled components
const Container = styled.div`
  min-height: 90vh;
`

const InnerContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    width: 88%;
  }
`

const HeroSection = styled.div`
  height: 30vh;
  background-color: #ffe1ac;
  margin-top: 11vh;
  margin-bottom: 10vh;
  border-radius: 5vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 501px) and (max-width: 1200px) {
    margin-top: 7vh;
    height: 26vh;
    border-radius: 10vw;
    justify-content: start;
    margin-bottom: 6vh;
  }
  @media (max-width: 500px) {
    margin-top: 5vh;
    height: 16vh;
    border-radius: 8vw;
    justify-content: start;
    margin-bottom: 6vh;
  }
`

const BackgroundWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

const BackgroundImage = styled.img`
  height: 100%;
  border-radius: 4vw;
  width: 100%;

  object-fit: cover;
`

const OverlayWrapper = styled.div`
  position: absolute;
  height: 34vh;
  top: -4.3vh;
  left: 70vw;
  z-index: 3;
  @media (min-width: 501px) and (max-width: 1200px) {
    left: 54vw;
    top: -4.3vh;
    height: 30vh;
  }
  @media (max-width: 500px) {
    left: 54vw;
    top: -2.3vh;
    height: 18vh;
  }
`

const OverlayImage = styled.img`
  height: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 4;
  text-align: center;
  @media (max-width: 1200px) {
    margin-left: 5vw;
    text-align: start;
    align-items: start;
  }
`

const Heading = styled.div`
  font-size: 2vw;
  font-weight: 400;
  @media (min-width: 768px) and (max-width: 1200px) {
    font-size: 3.8vw;
    letter-spacing: 0.5vw;
  }
  @media (max-width: 767px) {
    font-size: 3.4vw;
    letter-spacing: 0.5vw;
  }
`

const Heading1 = styled.div`
  font-size: 2vw;
  font-weight: 400;
  @media (min-width: 501px) and (max-width: 1200px) {
    font-size: 3.8vw;
    font-wieght: 600;
    letter-spacing: 0.5vw;
  }
  @media (max-width: 500px) {
    font-size: 4.7vw;
    font-wieght: 700;
    letter-spacing: 0.5vw;
  }
`

const Button = styled.button`
  padding: 0.7vw 1.3vw;
  margin-top: 2vh;
  background-color: #faba49;
  color: #000;
  font-size: 1.1vw;
  border: 1px solid #faba49;
  border-radius: 4vw;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 501px) and (max-width: 1200px) {
    margin-top: 0.8vh;
    padding: 1.2vw 2.2vw;
    font-size:  2.6vw;
    letter-spacing: 0.2vw;
  }
  @media (max-width: 500px) {
    margin-top: 0.8vh;
    padding: 1.6vw 2.6vw;
    font-size: 4vw;
    letter-spacing: 0.2vw; /* Corrected "letter-spaing" to "letter-spacing" */
  }
`

const SearchContainer = styled.div`
  position: relative;
  width: 18vw;
  margin-right: 6.5vw;
  margin-top: 2vh;
  @media (min-width: 501px) and (max-width: 1200px) {
    width: 83%;
    margin-right: 0;
  }
  @media (max-width: 500px) {
    width: 83%;
    margin-right: 0;
  }
`

const SearchInput = styled.input`
  padding: 0.7vw 2.5vw; /* Adjusted padding to make space for the icon */
  width: 100%;
  border-radius: 4vw;
  font-size: 1.1vw;
  border: 1px solid #faba49;
  transition: border-color 0.3s;
  &:focus {
    background-color: #fff7d4;
    border: 1px solid #faba49;
    outline: none;
  }
  @media (min-width: 501px) and (max-width: 1200px) {
    padding: 2vw 7vw;
    font-size: 3.8vw;
  }
  @media (max-width: 500px) {
    padding: 2vw 7vw;
    font-size: 3.8vw;
  }
`

const SearchIcon = styled(SearchIconSVG)`
  position: absolute;
  top: 50%;
  left: 0.7vw; /* Adjust as needed */
  transform: translateY(-50%);
  width: 1.2vw; /* Adjust size as needed */
  height: 1.2vw; /* Adjust size as needed */
  color: #faba49; /* You can adjust the color or apply a fill if needed */
  @media (min-width: 768px) and (max-width: 1200px) {
    width: 3vw;
    height: 3vw;
    left: 1.7vw;
  }
  @media (max-width: 767px) {
    width: 3vw;
    height: 3vw;
    left: 1.7vw;
  }
`

// Styled components
const GridContainer = styled.div`
  display: grid;
  height: auto;
  grid-template-columns: repeat(5, 1fr);
  gap: 3vh;
  @media (min-width: 501px) and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 3vh;
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 2vh;
  }
`

const GridItem = styled.div`
  border: 1.5px solid #faba49;
  cursor: pointer;
  border-radius: 3vw;
  display: flex;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (min-width: 501px) and (max-width: 1200px) {
    width: 99%;
    border-radius: 4vw;
  }

  @media (max-width: 500px) {
    width: 99%;
    border-radius: 7vw;
  }
`

const StyledDiv4 = styled.div`
  margin-left: 5%;
  margin-top: 2.5vh;
  font-size: 6vw;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #faba49;
  }
`

const StyledButton1 = styled.button`
  cursor: pointer;
  padding: 0rem 1.2rem;
  height: 32px;
  font-family: inherit;
  font-size: inherit;
  position: relative;
  letter-spacing: 0.05rem;
  font-weight: 400;
  font-size: 16px;
  border-radius: 400px;
  border: 2px solid #faba49;
  overflow: hidden;
  background: #faba49;
  color: black;
  transition: color 0.4s;
  position: relative;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    content: '';
  }

  &::before {
    background: #fadaa1;

    width: 120%;
    left: -10%;
    transform: skew(30deg);
    transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
  }

  &:hover::before {
    transform: translate3d(100%, 0, 0);
  }

  &:hover span {
    color: black;
  }

  span {
    position: relative;
    z-index: 10;
    transition: color 0.4s;
  }
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 18.5vh;
  cursor: pointer;
  padding: 1.5vh;
  justify-content: center;
  border: 1px solid #faba49;
  border-radius: 2.5vw;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.04);
    background-color: #fadaa1;
  }
  @media (min-width: 501px) and (max-width: 1200px) {
    height: 14vh;
    border-radius: 5vw;
  }

  @media (max-width: 500px) {
    height: 14vh;
    border-radius: 7vw;
  }
`

const CardImage = styled.img`
  width: auto;
  height: 8vh;
  object-fit: cover;
  background-color: transparent;
  @media (min-width: 501px) and (max-width: 1200px) {
    height: 9vh;
  }

  @media (max-width: 500px) {
    height: 6vh;
  }
`

const Title = styled.div`
  font-size: 1.2vw;
  font-weight: 400;
  margin-top: 1.5vh;
  text-align: center;
  line-height: 1.2;
  letter-spacing: 2px;
  @media (min-width: 501px) and (max-width: 1200px) {
    font-size: 3.1vw;
  }

  @media (max-width: 500px) {
    font-size: 3.4vw;
  }
`

const HideContainer = styled.div`
  @media (max-width: 1200px) {
    display: none;
  }
`

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const DialogBox = styled.div`
  background: #fff;
  border-radius: 1vw;
  width: 30vw;
  overflow: hidden;
  max-height: 60vh;
  padding-top: 2vw;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 2vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  @media (min-width: 501px) and (max-width: 1200px) {
    width: 80vw;
    max-height: 70vh;
    padding-left:5vw;
  }

  @media (max-width: 500px) {
    width: 80vw;
    max-height: 80vh;
    padding-left:5vw;
  }
`

const Newhome = () => {
  const deviceType = getDeviceType() // Ensure this function is defined properly and not conditional
  const [isActive, setIsActive] = useState(false)
  const { phoneNumber, carShops, distances } = useDevice() // Ensure useDevice doesn't depend on conditions
  const [locationAllowed, setLocationAllowed] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const hasRequestedPermission = useRef(false)
  const [originalDistances, setOriginalDistances] = useState([])
  const aboutRef = useRef(null)
  const [services, setServices] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [carDetails, setCarDetails] = useState([])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [lastCarDetailId, setLastCarDetailId] = useState(
    localStorage.getItem('lastCarDetailId'),
  )

  useEffect(() => {
    const savedId = localStorage.getItem('lastCarDetailId');
    if (savedId && savedId !== lastCarDetailId) {
      setLastCarDetailId(savedId);
    }
  
    const carDetail = carDetails.find(
      (detail) => Number(detail.id) === Number(lastCarDetailId)
    );
  
    // Save vehicle_type in localStorage if found
    if (carDetail?.brand_family?.vehicle_type) {
      localStorage.setItem('vehicle_type', carDetail.brand_family.vehicle_type);
      console.log('Vehicle type saved:', carDetail.brand_family.vehicle_type);
    }
  }, [carDetails, lastCarDetailId]); 
  

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    // Check if location is already stored
    const latitude = localStorage.getItem('latitude')
    const longitude = localStorage.getItem('longitude')

    if (latitude && longitude) {
      setLocationAllowed(true)
      console.log('Location already saved.')
    } else if (!hasRequestedPermission.current) {
      requestLocationPermission()
    }
  }, [])

  useEffect(() => {
    // Retrieve merchantTransactionId from localStorage
    const merchantTransactionId = localStorage.getItem('merchantTransactionId')

    if (merchantTransactionId) {
      const checkPaymentStatus = async () => {
        try {
          const paymentStatusResponse = await fetch(
            'https://www.dsctech.in/api/check_payment_status/',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                merchantTransactionId: merchantTransactionId, // Using transactionId from localStorage
              }),
            },
          )

          // Handle the response from the API
          if (paymentStatusResponse.ok) {
            localStorage.removeItem('merchantTransactionId')
          } else {
            console.error('Error checking payment status')
          }
        } catch (error) {
          console.error(
            'An error occurred while checking payment status:',
            error,
          )
        }
      }

      checkPaymentStatus()
    }
  }, [])

  useEffect(() => {
    const fetchCarDetails = async () => {
      try {
        // Replace '<str:phone>' with the actual phoneNumber
        const response = await fetch(
          `https://www.dsctech.in/carshops/allvehicles/${phoneNumber}/`,
        )

        if (!response.ok) {
          throw new Error('Failed to fetch car details')
        }

        const data = await response.json()

        if (data.length > 0) {
          // Filter car details by unique brand_family name, keeping only the latest one
          const latestCars = data.reduce((acc, car) => {
            const existingCar = acc.find(
              (item) => item.brand_family.name === car.brand_family.name,
            )

            if (
              !existingCar ||
              new Date(car.created_on) > new Date(existingCar.created_on)
            ) {
              // If the current car is the latest one for that brand_family, update it
              acc = acc.filter(
                (item) => item.brand_family.name !== car.brand_family.name,
              )
              acc.push(car)
            }

            return acc
          }, [])

          // Set filtered car details (latest only for each brand_family)
          setCarDetails(latestCars)
          if (!localStorage.getItem('lastCarDetailId')) {
            localStorage.setItem('lastCarDetailId', latestCars[latestCars.length - 1].id);
          }
          
          if (!localStorage.getItem('vehicle_type')) {
            localStorage.setItem(
              'vehicle_type',
              latestCars[latestCars.length - 1].brand_family.vehicle_type
            );
          }
          
          // localStorage.setItem(
          //   'lastCarDetailId',
          //   latestCars[latestCars.length - 1].id,
          // )
          // localStorage.setItem(
          //   'vehicle_type',
          //   latestCars[latestCars.length - 1].brand_family.vehicle_type,
          // )
          // setLastCarDetailId(latestCars[latestCars.length - 1].id)
        }

        setLoading(false)
      } catch (error) {
        setError('Error fetching car details')
        setLoading(false)
        console.error('Error fetching car details:', error)
      }
    }

    if (phoneNumber) {
      fetchCarDetails()
    }
  }, [phoneNumber])

  useEffect(() => {
    // Set original distances when carShops is first loaded
    if (carShops.length > 0 && originalDistances.length === 0) {
      setOriginalDistances(distances)
    }
  }, [carShops, distances])

  const requestLocationPermission = () => {
    hasRequestedPermission.current = true
    const userConfirmed = window.confirm(
      'This application needs access to your location. Do you want to allow it?',
    )

    if (userConfirmed) {
      promptForLocation()
    } else {
      alert('Location access denied. Some features may not work properly.')
    }
  }

  const promptForLocation = async () => {
    console.log('promptForLocation called') // For debugging
    if (navigator.geolocation) {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0,
          })
        })

        const { latitude, longitude } = position.coords
        localStorage.setItem('latitude', latitude)
        localStorage.setItem('longitude', longitude)
        setLocationAllowed(true)
        window.location.reload()
        console.log('Location saved:', latitude, longitude)
      } catch (error) {
        console.error('Error getting location:', error)
        alert(
          'Failed to get location. Please enable location services and try again.',
        )
      }
    } else {
      alert('Geolocation is not supported by this browser.')
    }
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase())
  }

  const filteredCarShops = carShops.filter(
    (shop) =>
      shop.fields.shop_name.toLowerCase().includes(searchTerm) ||
      shop.fields.owner_name.toLowerCase().includes(searchTerm) ||
      shop.fields.address.toLowerCase().includes(searchTerm),
  )

  const navigate = useNavigate()

  const handleClick = (id, distance) => {
    const phoneNumber = localStorage.getItem('phoneNumber') // Retrieve phone number from local storage

    if (!phoneNumber) {
      alert('Please log in to continue.') // Show alert if phone number is not found
      return // Exit the function if phone number is not found
    }

    console.log(`Shop ID: ${id}, Distance: ${distance} kms`)
    navigate(`/carwash/${id}?distance=${distance}`)
  }

  const handleprofileClick = () => {
    navigate(`/profile`)
  }

  const [hiddenClass, setHiddenClass] = useState('')
  const [hiddenClassmobile, setHiddenClassmobile] = useState('')

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setHiddenClass('hidden')
      } else {
        setHiddenClass('')
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial check

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handleResizemobile = () => {
      if (window.innerWidth < 500) {
        setHiddenClassmobile('hidden')
      } else {
        setHiddenClassmobile('')
      }
    }

    window.addEventListener('resize', handleResizemobile)
    handleResizemobile() // Initial check

    return () => {
      window.removeEventListener('resize', handleResizemobile)
    }
  }, [])

  const [name, setName] = useState('')

  useEffect(() => {
    // Retrieve the name from localStorage
    const storedName = localStorage.getItem('name')
    if (storedName) {
      setName(storedName)
    }
  }, [])

  const navLinks = [
    { name: 'Home', path: '/' },
    { name: 'About Us', path: '/aboutus' },
    { name: 'My Bookings', path: '/orders' },
    { name: 'Share App', path: '#' }, // Placeholder path for the Share App button
  ]

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: 'Check out this app!',
          text: 'I thought you might like this app. Check it out!',
          url: window.location.href,
        })
        .then(() => console.log('Thanks for sharing!'))
        .catch((error) => console.log('Error sharing:', error))
    } else if (window.flutter_inappwebview) {
      // Notify Flutter to handle sharing
      window.flutter_inappwebview.callHandler('shareApp')
    } else {
      alert(
        'Web Share API not supported. Copy the URL manually: ' +
          window.location.href,
      )
    }
  }

  const handleNavigation = (path) => {
    navigate(path)
  }

  const handleLogout = () => {
    // Remove phone number from localStorage
    setIsActive(false)
    localStorage.removeItem('phoneNumber')
    localStorage.removeItem('latitude')
    localStorage.removeItem('longitude')
    navigate('/login')
  }

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(
          'https://www.dsctech.in/carshops/list-car-services/',
        )
        setServices(response.data)
      } catch (err) {
        setError('Failed to fetch services. Please try again later.')
      } finally {
        setLoading(false)
      }
    }

    fetchServices()
  }, [])

  if (loading) return <LoadingSpinner />
  const handleDialogOpen = () => {
    setIsDialogOpen(true)
  }

  const handleDialogClose = () => {
    setIsDialogOpen(false)
  }

  const handleDelete = async (vehicleId) => {
    const isConfirmed = window.confirm(
      'Are you sure you want to delete the car details?',
    )
    if (!isConfirmed) return // If user cancels, stop further execution.

    setLoading(true)

    try {
      const lastCarDetailId = localStorage.getItem('lastCarDetailId')

      // If the vehicle being deleted is the same as the lastCarDetailId, remove it from localStorage
      if (lastCarDetailId && lastCarDetailId === vehicleId.toString()) {
        localStorage.removeItem('lastCarDetailId')
      }

      const response = await fetch(
        `https://www.dsctech.in/carshops/vehicles/${vehicleId}/`,
        {
          method: 'DELETE',
        },
      )

      if (response.ok) {
        alert('Vehicle deleted successfully!')
        // Remove the deleted vehicle from the state
        setCarDetails((prevDetails) => {
          const updatedDetails = prevDetails.filter(
            (car) => car.id !== vehicleId,
          )

          // If there are remaining cars, set the lastCarDetailId to the id of the last car
          if (updatedDetails.length > 0) {
            const newLastCarDetailId =
              updatedDetails[updatedDetails.length - 1].id
            localStorage.setItem('lastCarDetailId', newLastCarDetailId)
            setLastCarDetailId(newLastCarDetailId)
          } else {
            // If no cars are left, clear the lastCarDetailId
            localStorage.removeItem('lastCarDetailId')
            setLastCarDetailId(null)
          }

          return updatedDetails
        })

        handleDialogClose()
      } else {
        alert('Failed to delete the vehicle.')
      }
    } catch (error) {
      console.error('Error deleting vehicle:', error)
      alert('An error occurred while deleting the vehicle.')
    } finally {
      setLoading(false)
    }
  }

  // Handle when a car is clicked (save the id to localStorage and update state)
  const handleCarClick = (id) => {
    localStorage.setItem('lastCarDetailId', id)
    setLastCarDetailId(id)
    handleDialogClose()
  }

  const selectedCarDetail = carDetails.find(
    (car) => Number(car.id) === Number(lastCarDetailId),
  )

  console.log('Selected Car Detail:', selectedCarDetail)

  const handleCardClick = (id) => {
    if (!phoneNumber) {
      alert('Please login')
      return
    }

    if (carDetails.length === 0) {
      alert('Please add a car details')
      return
    }
    localStorage.setItem('serviceId', id)
    navigate(`/shops/${id}`)
  }

  return (
    <div>
      <Container>
        <InnerContainer>
          {hiddenClass ? (
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '8vh',
                }}
              >
                <div
                  onClick={() => setIsActive(!isActive)}
                  className="button"
                  style={{ marginLeft: '0', zIndex: '7' }}
                >
                  <div
                    className={`burger ${isActive ? 'burgerActive' : ''}`}
                  ></div>
                </div>
                <div
                  className={`dialog ${isActive ? 'dialogActive' : ''}`}
                  style={{ zIndex: '6' }}
                >
                  <div style={{ marginTop: '20vh' }}>
                    {navLinks.map((link, index) =>
                      link.name === 'Share App' ? (
                        <StyledDiv4 key={index} onClick={handleShare}>
                          {link.name}
                        </StyledDiv4>
                      ) : (
                        <StyledDiv4
                          key={index}
                          onClick={() => handleNavigation(link.path)}
                        >
                          {link.name}
                        </StyledDiv4>
                      ),
                    )}
                  </div>
                  <StyledButton1
                    style={{
                      marginRight: '5%',
                      width: '90%',
                      height: '6%',
                      marginLeft: '5%',
                      marginTop: '3.5vh',
                    }}
                    onClick={handleLogout}
                  >
                    <span>Logout</span>
                  </StyledButton1>
                </div>
                <div
                  style={{
                    height: '6vh',
                    width: '6vh',
                    border: '1px solid #FABA49',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={handleprofileClick}
                >
                  <img src={profile} height="60%" width="auto" />
                </div>
              </div>
              <div
                style={{
                  fontSize: hiddenClassmobile ? '4vw' : '2.6vw',
                  letterSpacing: '0.5vw',
                  marginTop: '2vh',
                  color: 'rgba(0, 0, 0, 0.6)',
                }}
              >
                Hello {name},
              </div>
              <div
                style={{
                  fontSize: hiddenClassmobile ? '4vw' : '2.6vw',
                  letterSpacing: '0.5vw',
                }}
              >
                What service do you need?
              </div>
              <div
                style={{
                  width: '100%',
                  marginTop: '4vh',
                  backgroundColor: '#ffe1ac',
                  height: '12vh',
                  borderRadius: '8vw',
                  display: 'flex',
                  alignItems: carDetails.length > 0 ? 'end' : 'center',
                  justifyContent: carDetails.length > 0 ? 'start' : 'center',
                  overflow: 'hidden',
                }}
              >
                {selectedCarDetail && (
                  <>
                    <img
                      src={`https://www.dsctech.in${selectedCarDetail.brand_family.image}`}
                      alt={selectedCarDetail.brand_family.name}
                      style={{
                        width:hiddenClassmobile ? '45%': '40%',
                        height: 'auto',
                        marginBottom: hiddenClassmobile ? '-2vh': '-3vw',
                        marginLeft: '-8vw',
                        borderRadius: '1vw',
                      }}
                    />
                    <div
                      style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'start',
                        marginLeft: '1vw',
                      }}
                    >
                      <Title
                        style={{
                          fontWeight: '400',
                          fontSize: hiddenClassmobile ? '4vw' : '3.1vw',
                          letterSpacing: '0.5vw',
                          marginTop: '0vh',
                          marginRight: '1vw',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {selectedCarDetail.brand_family.name.length > 15
                          ? `${selectedCarDetail.brand_family.name.substring(
                              0,
                              15,
                            )}...`
                          : selectedCarDetail.brand_family.name}
                      </Title>

                      <Button onClick={handleDialogOpen}>Change Car</Button>
                    </div>
                  </>
                )}
                {carDetails.length === 0 && (
                  <Button onClick={() => navigate('/carbrand')}>Add Car</Button>
                )}
              </div>
              <DialogOverlay isOpen={isDialogOpen}>
                  <DialogBox
                    style={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <div style={{ marginBottom: '2vh', fontSize: hiddenClassmobile ? '4vw' : '3.1vw',}}>
                      Select a New Car
                    </div>

                    <div
                      style={{
                        overflowY: 'auto',
                        flex: 1,
                        gap: '2vh',
                      }}
                    >
                      {carDetails
                        .slice(0)
                        .reverse()
                        .map((car, index) => (
                          <div
                            key={index}
                            style={{
                              height: '10vh',
                              marginBottom: '2vh',
                              width: '95%',
                              overflow: 'hidden',
                              border: '1px solid #faba49',
                              borderRadius: hiddenClassmobile ? '8vw' : '5vw',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'start',
                              cursor: 'pointer',
                              backgroundColor:
                                car.id === parseInt(lastCarDetailId)
                                  ? '#faba49'
                                  : 'transparent',
                            }}
                            onClick={() => handleCarClick(car.id)}
                          >
                            <img
                              src={`https://www.dsctech.in${car.brand_family.image}`}
                              alt={car.brand_family.name}
                              style={{
                                width: '20%',
                                marginLeft: '1.1vw',
                                height: 'auto',
                                borderRadius: '1vw',
                              }}
                            />
                            <div>
                              <div
                                style={{ fontSize: hiddenClassmobile ? '4vw' : '3.1vw', marginLeft: '1vw' }}
                              >
                                {car.brand_family.name}
                              </div>
                              <div
                                style={{
                                  fontSize: hiddenClassmobile ? '3vw' : '3.1vw',
                                  marginLeft: '1vw',
                                  opacity: '0.8',
                                }}
                              >
                                {car.brand.name}
                              </div>
                            </div>
                            <div
                              onClick={() => handleDelete(car.id)}
                              style={{
                                marginLeft: 'auto',
                                marginRight: '1.1vw',
                                width: '7vh',
                                borderRadius: '50%',
                                height: '7vh',
                                backgroundColor: '#fff7d4',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                              }}
                            >
                              <img
                                src={Delete}
                                alt="Delete"
                                style={{
                                  width: '45%',
                                  marginLeft: '0.1vw',
                                }}
                              />
                            </div>
                          </div>
                        ))}
                    </div>

                    <div
                      style={{
                        position: 'sticky',
                        bottom: '0',
                        paddingTop: '2vh',
                        left: '0',
                        right: '0',
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: '#fff',
                        zIndex: 1, // Ensure it stays on top of scrolling content
                      }}
                    >
                      <Button
                        onClick={handleDialogClose}
                        style={{ backgroundColor: '#fff7d4', marginTop: '0vh' }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => navigate('/carbrand')}
                        style={{ marginTop: '0vh' }}
                      >
                        Add New Car
                      </Button>
                    </div>
                  </DialogBox>
                </DialogOverlay>
              <Heading1 style={{marginTop:"2vh", marginBottom:"2vh"}}>Select Service</Heading1>
              <GridContainer>
                    {services.map((service) => (
                      <Card
                        key={service.id}
                        onClick={() => handleCardClick(service.id)}
                      >
                        {service.image ? (
                          <CardImage
                            src={`https://www.dsctech.in${service.image}`}
                            alt={service.name}
                          />
                        ) : (
                          <CardImage
                            src="https://via.placeholder.com/300x200"
                            alt="Placeholder"
                          />
                        )}
                        <Title>{service.name}</Title>
                      </Card>
                    ))}
                  </GridContainer>
              <HeroSection>
                <BackgroundWrapper>
                  <BackgroundImage src={herocontainer} alt="Background" />
                </BackgroundWrapper>
                <OverlayWrapper>
                  <OverlayImage src={hero} alt="Overlay" />
                </OverlayWrapper>
                <Content>
                  <div
                    style={{
                      fontSize: hiddenClassmobile ? '4vw' : '3.1vw',
                      letterSpacing: '0.2vw',
                    }}
                  >
                    Premium Car Wash at
                    <br />
                    Your Doorstep
                  </div>
                  <Button >
                  Get Our App
                  </Button>
                </Content>
              </HeroSection>
            </div>
          ) : (
            <HideContainer>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: '4vh',
                }}
              >
                <Heading>Select Service</Heading>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '60vw', marginTop: '3vh' }}>
                  <GridContainer>
                    {services.map((service) => (
                      <Card
                        key={service.id}
                        onClick={() => handleCardClick(service.id)}
                      >
                        {service.image ? (
                          <CardImage
                            src={`https://www.dsctech.in${service.image}`}
                            alt={service.name}
                          />
                        ) : (
                          <CardImage
                            src="https://via.placeholder.com/300x200"
                            alt="Placeholder"
                          />
                        )}
                        <Title>{service.name}</Title>
                      </Card>
                    ))}
                  </GridContainer>
                </div>
                {!phoneNumber ? (
                  <div
                    style={{
                      width: '35vw',
                      marginTop: '4vh',
                      backgroundColor: '#ffe1ac',
                      height: '20vh',
                      marginLeft: '3vw',
                      borderRadius: '2.5vw',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      overflow: 'hidden',
                    }}
                  >
                    <Button onClick={() => navigate('/login')}>
                      Please Login
                    </Button>
                  </div>
                ) : (
                  <div
                    style={{
                      width: '35vw',
                      marginTop: '4vh',
                      backgroundColor: '#ffe1ac',
                      height: '20vh',
                      marginLeft: '3vw',
                      borderRadius: '2.5vw',
                      display: 'flex',
                      alignItems: carDetails.length > 0 ? 'end' : 'center',
                      justifyContent:
                        carDetails.length > 0 ? 'start' : 'center',
                      overflow: 'hidden',
                    }}
                  >
                    {selectedCarDetail  && (
                      <>
                        <img
                          src={`https://www.dsctech.in${selectedCarDetail.brand_family.image}`}
                          alt={selectedCarDetail.brand_family.name}
                          style={{
                            width: '22vw',
                            height: 'auto',
                            marginBottom: '-6vh',
                            marginLeft: '-7vw',
                            borderRadius: '1vw',
                          }}
                        />
                        <div
                          style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'start',
                            marginLeft: '1vw',
                          }}
                        >
                          <Title
                            style={{
                              fontWeight: '400',
                              fontSize: '1.4vw',
                              marginTop: '0vh',
                              marginBottom: '-0.6vh',
                              marginRight: '1vw',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {selectedCarDetail.brand_family.name.length > 15
                              ? `${selectedCarDetail.brand_family.name.substring(
                                  0,
                                  15,
                                )}...`
                              : selectedCarDetail.brand_family.name}
                          </Title>

                          <Button onClick={handleDialogOpen}>Change Car</Button>
                        </div>
                      </>
                    )}
                    {carDetails.length === 0 && (
                      <Button onClick={() => navigate('/carbrand')}>
                        Add Car
                      </Button>
                    )}
                  </div>
                )}

                <DialogOverlay isOpen={isDialogOpen}>
                  <DialogBox
                    style={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <div style={{ marginBottom: '2vh', fontSize: '1.5vw' }}>
                      Select a New Car
                    </div>

                    <div
                      style={{
                        overflowY: 'auto',
                        flex: 1,
                        gap: '2vh',
                      }}
                    >
                      {carDetails
                        .slice(0)
                        .reverse()
                        .map((car, index) => (
                          <div
                            key={index}
                            style={{
                              height: '10vh',
                              marginBottom: '2vh',
                              width: '99.5%',
                              overflow: 'hidden',
                              border: '1px solid #faba49',
                              borderRadius: '3vw',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'start',
                              cursor: 'pointer',
                              backgroundColor:
                                car.id === parseInt(lastCarDetailId)
                                  ? '#faba49'
                                  : 'transparent',
                            }}
                            onClick={() => handleCarClick(car.id)}
                          >
                            <img
                              src={`https://www.dsctech.in${car.brand_family.image}`}
                              alt={car.brand_family.name}
                              style={{
                                width: '20%',
                                marginLeft: '1.1vw',
                                height: 'auto',
                                borderRadius: '1vw',
                              }}
                            />
                            <div>
                              <div
                                style={{ fontSize: '1.2vw', marginLeft: '1vw' }}
                              >
                                {car.brand_family.name}
                              </div>
                              <div
                                style={{
                                  fontSize: '1vw',
                                  marginLeft: '1vw',
                                  opacity: '0.8',
                                }}
                              >
                                {car.brand.name}
                              </div>
                            </div>
                            <div
                              onClick={() => handleDelete(car.id)}
                              style={{
                                marginLeft: 'auto',
                                marginRight: '1.1vw',
                                width: '7vh',
                                borderRadius: '50%',
                                height: '7vh',
                                backgroundColor: '#fff7d4',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                              }}
                            >
                              <img
                                src={Delete}
                                alt="Delete"
                                style={{
                                  width: '45%',
                                  marginLeft: '0.1vw',
                                }}
                              />
                            </div>
                          </div>
                        ))}
                    </div>

                    <div
                      style={{
                        position: 'sticky',
                        bottom: '0',
                        paddingTop: '2vh',
                        left: '0',
                        right: '0',
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: '#fff',
                        zIndex: 1, // Ensure it stays on top of scrolling content
                      }}
                    >
                      <Button
                        onClick={handleDialogClose}
                        style={{ backgroundColor: '#fff7d4', marginTop: '0vh' }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => navigate('/carbrand')}
                        style={{ marginTop: '0vh' }}
                      >
                        Add New Car
                      </Button>
                    </div>
                  </DialogBox>
                </DialogOverlay>
              </div>
              <HeroSection>
                <BackgroundWrapper>
                  <BackgroundImage src={herocontainer} alt="Background" />
                </BackgroundWrapper>
                <OverlayWrapper>
                  <OverlayImage src={hero} alt="Overlay" />
                </OverlayWrapper>
                <Content>
                  <Heading>
                    Premium Car Wash at
                    <br />
                    Your Doorstep
                  </Heading>
                  <Button>Get Our App</Button>
                </Content>
              </HeroSection>
            </HideContainer>
          )}
        </InnerContainer>
      </Container>
      {hiddenClass ? <></> : <Footer />}
    </div>
  )
}

export default Newhome
