import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDevice } from '../Components/Context/deviceContext'
import { useNavigate } from 'react-router-dom'
import { LoadingSpinner } from '../Components/LoadingSpinner '

// Styled Components
const Input = styled.input`
  margin-bottom: 3vh;
  padding: 10px;
  border-radius: 30px;
  background-color: #ffffff;
  border: 1px solid #ff8c00;
  font-size: 16px;
  width: 80%;

  &:focus {
    background-color: #fff7d4;
    border-color: #ff8c00;
    outline: none;
  }

  @media (max-width: 767px) {
    width: 88%;
    font-size: 14px;
    padding: 15px 10px;
  }
`

const Button = styled.button`
  padding: 0.5vw 2vw;
  width: 9.5vw;
  font-size: 1.2vw;
  background-color: #fadaa1;
  border: 2px solid #faba49;
  border-radius: 2vw;
  cursor: pointer;

  &:hover {
    background-color: #faba49;
  }

  @media (max-width: 500px) {
    width: 30vw;
    font-size: 4.5vw;
    border-radius: 7vw;
  }
`

const SelectInput = styled.select`
  padding: 0.5vw;
  margin-top: 1vh;
  border-radius: 2vw;
  border: 2px solid #faba49;
  background-color: white;
  font-size: 1vw;

  &:focus {
    border-color: #faba49;
    background-color: #ffebcc;
  }

  @media (max-width: 767px) {
    font-size: 4.5vw;
    padding: 1.7vw;
    border-radius: 6vw;
  }
`

const CarShopForm = () => {
  const navigate = useNavigate();
  const { phoneNumber } = useDevice();
  const [loading, setLoading] = useState(true);
  const [carShopData, setCarShopData] = useState({
    shop_name: '',
    owner_name: '',
    phone1: '',
    address: '',
    opening_time: '08:00:00',
    closing_time: '20:00:00',
  });
  const [image, setImage] = useState(null);
  const [services, setServices] = useState([
    {
      service_name: '',
      cost: '',
      description: '',
      car_type_status: 'Sedan',
      duration_in_hours: '',
    },
  ]);

  const latitude = localStorage.getItem('latitude');
  const longitude = localStorage.getItem('longitude');

  // Fetch car shop details
  useEffect(() => {
    const fetchCarShopDetails = async () => {
      try {
        const response = await fetch(`https://www.dsctech.in/carshops/owner/${phoneNumber}/`);
        if (response.ok) {
          const data = await response.json();
          if (data) {
            setCarShopData({
              shop_name: data.shop_name,
              owner_name: data.owner_name,
              phone1: data.phone1,
              address: data.address,
              opening_time: data.opening_time,
              closing_time: data.closing_time,
            });
            setServices(data.services || []);
          }
        } else {
          console.error('Error fetching car shop details:', await response.json());
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCarShopDetails();
  }, [phoneNumber]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCarShopData((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleServiceChange = (index, e) => {
    const { name, value } = e.target;
    const updatedServices = [...services];
    updatedServices[index][name] = value;
    setServices(updatedServices);
  };

  const addService = () => {
    setServices([
      ...services,
      {
        service_name: '',
        cost: '',
        description: '',
        car_type_status: 'Sedan',
        duration_in_hours: '',
      },
    ]);
  };

  const deleteService = (index) => {
    const updatedServices = services.filter((_, i) => i !== index);
    setServices(updatedServices);
  };

  const validateServices = () => services.length > 0;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // if (!validateServices()) {
    //   alert('Please add at least one service.');
    //   setLoading(false);
    //   return;
    // }

    const payload = {
      phone: phoneNumber,
      shop_name: carShopData.shop_name,
      latitude,
      longitude,
      owner_name: carShopData.owner_name,
      phone1: carShopData.phone1,
      address: carShopData.address,
      opening_time: carShopData.opening_time,
      closing_time: carShopData.closing_time,
    };

    try {
      // Check if the car shop already exists
      const response = await fetch(`https://www.dsctech.in/carshops/owner/${phoneNumber}/`);
      if (response.ok) {
        const existingData = await response.json();
        if (existingData) {
          // If data exists, update it using PUT request
          const updateResponse = await fetch(`https://www.dsctech.in/carshops/owner/${phoneNumber}/`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });

          if (updateResponse.ok) {
            alert('Car shop details updated successfully!');
            navigate('/'); // Navigate after successful update
          } else {
            const updateErrorData = await updateResponse.json();
            console.error('Update Error:', updateErrorData);
            alert(`Failed to update: ${updateErrorData.message || 'Unknown error'}`);
          }
        }
      } else {
        // If no data exists, create a new car shop using POST request
        const createResponse = await fetch('https://www.dsctech.in/api/add-carshop', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (createResponse.ok) {
          const responseData = await createResponse.json();
          const carshop_id = responseData.carshop_id; // Retrieve carshop_id
          localStorage.setItem('shop_id', carshop_id);
          alert('Car shop successfully added!');

          // Step 2: Upload the car shop image
          const formData = new FormData();
          formData.append('upload_carshop_image', image); // Append the image file to FormData

          const uploadResponse = await fetch(`https://www.dsctech.in/carshops/${carshop_id}/`, {
            method: 'PUT',
            body: formData, // Send FormData directly
          });

          if (uploadResponse.ok) {
            // Handle successful image upload if needed
          } else {
            const uploadErrorData = await uploadResponse.json();
            console.error('Image Upload Error:', uploadErrorData);
          }

          navigate('/'); // Navigate after successful creation
        } else {
          const createErrorData = await createResponse.json();
          console.error('Create Error:', createErrorData);
          alert(`Failed to create: ${createErrorData.message || 'Unknown error'}`);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <LoadingSpinner />;

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ width: '70%', margin: 'auto' }}>
        <h1>Car Shop Details</h1>
        <form onSubmit={handleSubmit}>
          <Input
            type="text"
            name="shop_name"
            placeholder="Shop Name"
            value={carShopData.shop_name}
            onChange={handleInputChange}
            required
            style={{ width: '100%' }}
          />
          <Input
            type="text"
            name="owner_name"
            placeholder="Owner Name"
            value={carShopData.owner_name}
            onChange={handleInputChange}
            required
            style={{ width: '100%' }}
          />
          <Input
            type="text"
            name="phone1"
            placeholder="Alternate Phone"
            value={carShopData.phone1}
            onChange={handleInputChange}
            style={{ width: '100%' }}
          />
          <Input
            type="text"
            name="address"
            placeholder="Address"
            value={carShopData.address}
            onChange={handleInputChange}
            required
            style={{ width: '100%' }}
          />
          <div style={{ color: 'rgb(0, 0, 0, 0.6)', fontSize: '18px', marginBottom: '1.5vh' }}>
            Shop Image
          </div>
          <Input
            type="file"
            name="upload_carshop_image"
            onChange={handleImageChange}
            required
            style={{ width: '100%' }}
          />
          <div style={{ color: 'rgb(0, 0, 0, 0.6)', fontSize: '18px', marginBottom: '1.5vh' }}>
            Opening Time
          </div>
          <Input
            type="text"
            name="opening_time"
            value={carShopData.opening_time}
            onChange={handleInputChange}
            required
            style={{ width: '100%' }}
          />
          <div style={{ color: 'rgb(0, 0, 0, 0.6)', fontSize: '18px', marginBottom: '1.5vh' }}>
            Closing Time (add 24 hours time)
          </div>
          <Input
            type="text"
            name="closing_time"
            value={carShopData.closing_time}
            onChange={handleInputChange}
            required
            style={{ width: '100%' }}
          />
          {/* <h2 style={{ margin: '2vh 0' }}>Services</h2>
          {services.map((service, index) => (
            <div key={index} style={{ marginBottom: '1.5vh' }}>
              <Input
                type="text"
                name="service_name"
                placeholder="Service Name"
                value={service.service_name}
                onChange={(e) => handleServiceChange(index, e)}
                required
                style={{ width: '100%' }}
              />
              <Input
                type="number"
                name="cost"
                placeholder="Cost"
                value={service.cost}
                onChange={(e) => handleServiceChange(index, e)}
                required
                style={{ width: '100%' }}
              />
              <Input
                type="text"
                name="description"
                placeholder="Description"
                value={service.description}
                onChange={(e) => handleServiceChange(index, e)}
                style={{ width: '100%' }}
              />
              <SelectInput
                name="car_type_status"
                value={service.car_type_status}
                onChange={(e) => handleServiceChange(index, e)}
                style={{ width: '102.3%', marginBottom:'3vh', padding: "10px" }}
              >
                <option value="Sedan">Sedan</option>
                <option value="SUV">SUV</option>
                <option value="Truck">Truck</option>
                <option value="Hatchback">Hatchback</option>
              </SelectInput>
              <Input
                type="number"
                name="duration_in_hours"
                placeholder="Duration (in hours)"
                value={service.duration_in_hours}
                onChange={(e) => handleServiceChange(index, e)}
                required
                style={{ width: '100%' }}
              />
              <Button
                type="button"
                onClick={() => deleteService(index)}
                style={{ width: '100%', marginTop: '1vh', marginBottom:'2vh' }}
              >
                Delete Service
              </Button>
            </div>
          ))}
          <Button type="button" onClick={addService} style={{ width: '100%', marginTop: '0vh' }}>
            Add Another Service
          </Button> */}
          <Button type="submit" style={{ width: '100%', marginTop: '2vh', marginBottom:'6vh'  }}>
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};

export default CarShopForm;