import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { LoadingSpinner } from '../Components/LoadingSpinner '
import styled from 'styled-components'
import arrow from '../Components/Assets/arrow.svg'
import atlocation from '../Components/Assets/atlocation.svg'

const Container = styled.div`
  min-height: 100vh;
`

const InnerContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
    width: 88%;
  }
`

const GridContainer = styled.div`
  display: grid;
  height: auto;
  gap: 3.5vw;
  grid-template-columns: repeat(2, 1fr);
  @media (min-width: 501px) and (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 2vh;
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 2vh;
  }
`

const GridItem = styled.div`
  border: 1.5px solid #faba49;
  cursor: pointer;
  border-radius: 3vw;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: #ffe9c2;
  }
  @media (min-width: 501px) and (max-width: 1200px) {
    width: 99%;
    border-radius: 4vw;
  }

  @media (max-width: 500px) {
    width: 99%;
    border-radius: 7vw;
  }
`

const Button = styled.button`
  border: 1px solid #faba49;
  margin-top: 2vh;
  height: 6vh;
  width: 10vw;
  background-color: #faba49;
  color: #000;
  font-size: 1.1vw;
  border-radius: 4vw;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 501px) and (max-width: 1200px) {
    margin-top: 0.8vh;
    padding: 0.8vw 2.2vw;
    font-size: 1.5vw;
    letter-spacing: 0.5vw;
  }
  @media (max-width: 500px) {
    margin-top: 0.8vh;
    padding: 1.2vw 2.2vw;
    font-size: 2.5vw;
    letter-spacing: 0.5vw;
  }
`

const Button1 = styled.button`
  border: 1px solid #faba49;
  padding: 0.7vw 1.3vw;
  margin-top: 2vh;
  background-color: #faba49;
  color: #000;
  font-size: 1.1vw;
  border-radius: 4vw;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 501px) and (max-width: 1200px) {
    margin-top: 0.8vh;
    padding: 0.8vw 2.2vw;
    font-size: 1.5vw;
    letter-spacing: 0.5vw;
  }
  @media (max-width: 500px) {
    margin-top: 0.8vh;
    padding: 1.2vw 2.2vw;
    font-size: 2.5vw;
    letter-spacing: 0.5vw;
  }
`

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const DialogBox = styled.div`
  background: #fff;
  border-radius: 1vw;
  width: 30vw;
  max-height: 60vh;
  overflow-y: scroll;
  padding: 2vw;
  padding-bottom: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  @media (min-width: 501px) and (max-width: 1200px) {
    width: 80vw;
    max-height: 70vh;
    padding-left:5vw;
    padding: 3vw;
  }
  @media (max-width: 500px) {
    width: 80vw;
    max-height: 80vh;
    padding-left:5vw;
    padding: 4vw;
  }
`

const CouponList = styled.div`
  list-style: none;
  padding: 0;
`

const CouponItem = styled.li`
  padding: 1.3vw 1.3vw;
  display: flex;
  border: 1px solid #ff8c00;
  background-color: transparent;
  margin-bottom: 2vh;
  border-radius: 3vw;
  &:hover {
    background-color: #fff7d4;
  }
  @media (min-width: 501px) and (max-width: 1200px) {
    padding: 2.3vw 2.3vw;
    border-radius: 5vw;
  }
  @media (max-width: 500px) {
    padding: 2.3vw 2.3vw;
    border-radius: 6vw;
  }
`

const ApplyButton = styled.button`
  padding: 0.8vw;
  width: 6vw;
  background-color: #fff7d4;
  color: black;
  border: 1px solid #ff8c00;
  border-radius: 3vw;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1.2vw;
  letter-spacing: 1px;
  &:hover {
    background-color: #ff8c00;
  }
  @media (min-width: 501px) and (max-width: 1200px) {
    width: 15vw;
    font-size: 3.1vw;
    padding: 1.8vw;
    border-radius: 5vw;
  }
  @media (max-width: 500px) {
    width: 20vw;
    padding: 2.8vw;
    font-size: 4vw;
    border-radius: 8vw;
  }
`

const Shop = () => {
  const { id } = useParams()
  const [shops, setShops] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [ratings, setRatings] = useState([])
  const navigate = useNavigate()
  const [showDialog, setShowDialog] = useState(false)
  const [coupons, setCoupons] = useState([])
  const handleViewCouponsClick = () => {
    setShowDialog(true)
    fetchCoupons()
  }

   const [hiddenClass, setHiddenClass] = useState('')
      const [hiddenClassmobile, setHiddenClassmobile] = useState('')
    
      useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 1200) {
            setHiddenClass('hidden')
          } else {
            setHiddenClass('')
          }
        }
    
        window.addEventListener('resize', handleResize)
        handleResize() // Initial check
    
        return () => {
          window.removeEventListener('resize', handleResize)
        }
      }, [])
    
      useEffect(() => {
        const handleResizemobile = () => {
          if (window.innerWidth < 500) {
            setHiddenClassmobile('hidden')
          } else {
            setHiddenClassmobile('')
          }
        }
    
        window.addEventListener('resize', handleResizemobile)
        handleResizemobile() // Initial check
    
        return () => {
          window.removeEventListener('resize', handleResizemobile)
        }
      }, [])

  const fetchCoupons = async () => {
    try {
      const response = await fetch(
        `https://www.dsctech.in/carshops/car-service/${id}/`,
      )
      if (!response.ok) {
        throw new Error('Failed to fetch coupons')
      }
      const data = await response.json()

      // Check if the data contains car_service_details and sub_carservices
      if (
        data.car_service_details &&
        Array.isArray(data.car_service_details.sub_carservices)
      ) {
        setCoupons(data.car_service_details.sub_carservices) // Set sub_carservices to coupons
      } else {
        setCoupons([]) // Handle cases where sub_carservices is not an array or missing
        setError('No sub-car services found.')
      }
    } catch (error) {
      console.error('Error fetching coupons:', error)
      setError(error.message) // Optionally set the error message state to display an error message
    }
  }

  useEffect(() => {
    // Retrieve data from localStorage
    const latitude = localStorage.getItem('latitude')
    const longitude = localStorage.getItem('longitude')
    let vehicle_type = localStorage.getItem('vehicle_type') // Use let instead of const

    if (vehicle_type === 'SUV/MUV') {
      vehicle_type = 'SUV'
    }

    if (latitude && longitude && vehicle_type) {
      setLoading(true)
      // Make the GET request
      axios
        .get(
          `https://www.dsctech.in/api/carshops/${latitude}/${longitude}/${id}/${vehicle_type}/`,
        )
        .then((response) => {
          setShops(response.data)
        })
        .catch((err) => {
          console.error('Error fetching data:', err)
          setError('Failed to fetch shop data. Please try again later.')
          setLoading(false)
        })

      axios
        .get(`https://www.dsctech.in/carshops/ratings/user/${id}/`)
        .then((response) => {
          setRatings(response.data)
          setLoading(false)
        })
        .catch((err) => {
          console.error('Error fetching ratings:', err)
          setError('Failed to fetch ratings. Please try again later.')
          setLoading(false)
        })
    } else {
      setError('Location or vehicle type not found in localStorage.')
    }
  }, [id])

  if (loading) return <LoadingSpinner />

  const handleClick = (id, distance, price) => {
    navigate(`/carwash/${id}?distance=${distance}&price=${price}`);
  };

  const handleClick1 = () => {
    navigate(-1)
  }
  
  return (
    <Container>
      { hiddenClass ? (
         <InnerContainer>
             <div
            onClick={handleClick1}
            style={{
              height: hiddenClassmobile ? '5vh' : '7vh',
              width: hiddenClassmobile ? '5vh' : '7vh',
              borderRadius: '50%',
              backgroundColor: '#FABA49',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              marginTop: '6vh',
              marginBottom:'3vh'
            }}
          >
            <img
              src={arrow}
              alt="arrow"
              height="50%"
              style={{
                transform: 'rotate(-180deg)',
              }}
            />
          </div>

         {shops.length > 0 ? (
           <div style={{ marginBottom: '5vh' }}>
             <div
               style={{
                 display: 'flex',
                 justifyContent: 'space-between',
                 flexDirection:"column",
                 alignItems: 'start',
                 marginBottom: '5vh',
               }}
             >
               <div>
                 {shops[0]?.fields?.car_services ? (
                   <>
                     <div
                       key={shops[0].pk}
                       style={{ fontSize:  hiddenClassmobile ? '4.5vw' : '3.1vw', letterSpacing: '2.5px' }}
                     >
                       {shops[0].fields.car_services.service_name}
                     </div>
                     <div
                       style={{
                         fontSize:  hiddenClassmobile ? '3vw' : '2.1vw',
                         marginTop: '1vh',
                         width: '90%',
                         letterSpacing: '1.5px',
                       }}
                     >
                       {shops[0].fields.car_services.service_description}
                     </div>
                   </>
                 ) : (
                   <div>No service information available.</div>
                 )}
               </div>
 
               <div style={{ display: 'flex', justifyContent:"space-between", marginTop:'2vh' ,width:'100%'}}>
                 {ratings.overall_rating ? (
                   <div
                     style={{
                       height: '5.5vh',
                       width: '30vw',
                       fontSize: hiddenClassmobile ? '4vw' : '3.1vw',
                       backgroundColor: '#fff7d4',
                       borderRadius: hiddenClassmobile ? '8vw' : '5vw',
                       alignItems: 'center',
                       justifyContent: 'center',
                       display: 'flex',
                       marginRight: '1.2vw',
                       border: '1px solid #faba49',
                     }}
                   >
                     Rating - {ratings?.overall_rating}
                   </div>
                 ) : (
                   <></>
                 )}
                 {shops[0].fields.car_services?.subservices_count ? (
                   <Button onClick={handleViewCouponsClick} style={{height: '5.5vh',width:'40vw', marginTop:'0', borderRadius: hiddenClassmobile ? '8vw' : '5vw', fontSize: hiddenClassmobile ? '4vw' : '3.1vw',}}>
                     <div style={{ display: 'flex', alignItems: 'center' }}>
                       {shops[0]?.fields?.car_services?.subservices_count || 0}{' '}
                       Services
                       <div style={{ height: '2vh' }}>
                         <img
                           src={arrow}
                           alt="arrow"
                           height="100%"
                           style={{ marginLeft: '1.5vw' }}
                         />
                       </div>
                     </div>
                   </Button>
                 ) : (
                   <></>
                 )}
               </div>
             </div>
             <DialogOverlay show={showDialog}>
               <DialogBox>
                 <div style={{ fontSize: hiddenClassmobile ? '4.5vw' : '3.1vw', marginBottom: '3vh' }}>
                   Service Included
                 </div>
                 {Array.isArray(coupons) && coupons.length > 0 ? (
                   <CouponList>
                     {coupons.map((coupon, index) => (
                       <CouponItem key={index}>
                         <img   src={`https://www.dsctech.in${
                           coupon.image
                         }`}  style={{width: hiddenClassmobile ? '20vw' : '15vw', borderRadius:hiddenClassmobile ? '4vw' : '4vw', marginRight:'3vw', height:hiddenClassmobile ? '20vw' : '15vw'}}/>
                       <div>
                       <div  style={{fontSize:hiddenClassmobile ? '3.2vw' : '3.1vw', }}>{coupon.name}</div>
                        <div style={{fontSize:hiddenClassmobile ? '3.2vw' : '3.1vw', opacity:'70%', marginTop:'0.5vh' }}>
                        {coupon.description}
                        </div>
                       </div>
                       </CouponItem>
                     ))}
                   </CouponList>
                 ) : (
                   <div>No coupons available</div> // Optionally show a message if there are no coupons
                 )}
                 <div
                   style={{
                     position: 'sticky',
                     bottom: 0,
                     backgroundColor: 'white',
                     padding: '2vh',
                     textAlign: 'center',
                     borderTop: '1px solid #ccc',
                   }}
                 >
                   <ApplyButton onClick={() => setShowDialog(false)}>
                     Close
                   </ApplyButton>
                 </div>
               </DialogBox>
             </DialogOverlay>
 
             <GridContainer>
               {shops.map((shop, index) => (
                 <GridItem
                   key={shop.pk}
                   onClick={() => handleClick(shop.pk, shop.fields?.distance, shop.fields.car_services.calculated_price)}
                 >
                   <div style={{ display: 'flex' }}>
                     <div
                       style={{
                         height:  hiddenClassmobile ? "14vh": '12vh',
                         width:  hiddenClassmobile ? "14vh": '12vh',
                         borderRadius: '2vw',
                         backgroundColor: 'transparent',
                         overflow: 'hidden',
                         margin: '3vh',
                         display: 'flex',
                         alignItems: 'center',
                         justifyContent: 'center',
                       }}
                     >
                       <img
                         src={`https://www.dsctech.in${
                           shop.fields?.upload_carshop_image || ''
                         }`}
                         alt="Car Shop"
                         style={{
                           height: '100%',
                           width: '100%',
                           borderRadius:  hiddenClassmobile ? '6vw' : '4vw',
                         }}
                       />
                     </div>
                     <div
                       style={{
                         display: 'flex',
                         flexDirection: 'column',
                         justifyContent: 'start',
                         marginTop: 'auto',
                         marginBottom: 'auto',
                         marginLeft: '0.6vw',
                       }}
                     >
                       <div style={{ fontSize:  hiddenClassmobile ? '3.5vw' : '3.1vw' }}>
                         {shop.fields?.shop_name || 'Shop'}
                       </div>
                       <div style={{ color: 'rgb(0, 0, 0, 0.6)' , fontSize:  hiddenClassmobile ? '3.5vw' : '3.1vw'}}>
                         {shop.fields?.distance} kms away
                       </div>
                       <div style={{ color: 'rgb(0, 0, 0, 0.6)',fontSize:  hiddenClassmobile ? '3.5vw' : '3.1vw' }}>
                         {shop.fields?.address || 'No address available'}
                       </div>
                     </div>
                     <div
                       style={{
                         marginLeft: 'auto',
                         marginTop: 'auto',
                         marginBottom: 'auto',
                         marginRight: '3vh',
                       }}
                     >
                       <div
                         style={{
                           height:hiddenClassmobile? '5vh': '7vh',
                           width: hiddenClassmobile? '5vh':'7vh',
                           borderRadius: '50%',
                           backgroundColor: '#FFE9C2',
                           alignItems: 'center',
                           justifyContent: 'center',
                           display: 'flex',
                           border: '1px solid #faba49',
                         }}
                       >
                         <img
                           src={arrow}
                           alt="arrow"
                           height="40%"
                           style={{ marginLeft: '0.5vh' }}
                         />
                       </div>
                     </div>
                   </div>
                   <div
                     style={{
                       marginLeft: '3vh',
                       marginRight: '3vh',
                       marginBottom: '3vh',
                       display: 'flex',
                       justifyContent: 'space-between',
                     }}
                   >
                     {ratings &&
                       ratings.ratings &&
                       ratings.ratings[0]?.carservice?.duration_minutes && (
                         <div
                           style={{
                             height: '6vh',
                             width: '42vw',
                             fontSize: hiddenClassmobile ? '3.5vw' : '3.1vw' ,
                             backgroundColor: '#FFE9C2',
                             borderRadius: hiddenClassmobile ? '6vw' : '4vw',
                             alignItems: 'center',
                             justifyContent: 'center',
                             display: 'flex',
                             border: '1px solid #faba49',
                           }}
                         >
                           Washtime -{' '}
                           {ratings.ratings[0].carservice.duration_minutes}min
                         </div>
                       )}
 
                     <div
                       style={{
                         height: '6vh',
                         width: '25vw',
                         fontSize: hiddenClassmobile ? '3.5vw' : '3.1vw' ,
                         backgroundColor: '#FFE9C2',
                         borderRadius: hiddenClassmobile ? '6vw' : '4vw',
                         alignItems: 'center',
                         justifyContent: 'center',
                         display: 'flex',
                         border: '1px solid #faba49',
                       }}
                     >
                       ₹ {shop.fields.car_services.calculated_price}
                     </div>
                   </div>
                 </GridItem>
               ))}
             </GridContainer>
           </div>
         ) : (
           <div
             style={{
               width: '100%',
               marginTop: '6vh',
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               justifyContent: 'center',
             }}
           >
             <div>
               We're sorry, but there are no services in your location right now.
               We're constantly expanding, so please stay tuned!
             </div>
             <img src={atlocation} height="200" style={{ marginTop: '3vh' }} />
           </div>
         )}
       </InnerContainer>
      ): (
         <InnerContainer>
         {shops.length > 0 ? (
           <div style={{ marginBottom: '5vh' }}>
             <div
               style={{
                 marginTop: '5vh',
                 display: 'flex',
                 justifyContent: 'space-between',
                 alignItems: 'end',
                 marginBottom: '5vh',
               }}
             >
               <div>
                 {shops[0]?.fields?.car_services ? (
                   <>
                     <div
                       key={shops[0].pk}
                       style={{ fontSize: '1.8vw', letterSpacing: '2.5px' }}
                     >
                       {shops[0].fields.car_services.service_name}
                     </div>
                     <div
                       style={{
                         fontSize: '1.2vw',
                         marginTop: '1vh',
                         width: '70%',
                         letterSpacing: '1px',
                       }}
                     >
                       {shops[0].fields.car_services.service_description}
                     </div>
                   </>
                 ) : (
                   <div>No service information available.</div>
                 )}
               </div>
 
               <div style={{ display: 'flex', alignItems: 'end' }}>
                 {ratings.overall_rating ? (
                   <div
                     style={{
                       height: '6vh',
                       width: '8vw',
                       fontSize: '1.1vw',
                       backgroundColor: '#fff7d4',
                       borderRadius: '2vw',
                       alignItems: 'center',
                       justifyContent: 'center',
                       display: 'flex',
                       marginRight: '1.2vw',
                       border: '1px solid #faba49',
                     }}
                   >
                     Rating - {ratings?.overall_rating}
                   </div>
                 ) : (
                   <></>
                 )}
                 {shops[0].fields.car_services?.subservices_count ? (
                   <Button onClick={handleViewCouponsClick}>
                     <div style={{ display: 'flex', alignItems: 'center' }}>
                       {shops[0]?.fields?.car_services?.subservices_count || 0}{' '}
                       Services
                       <div style={{ height: '2.5vh' }}>
                         <img
                           src={arrow}
                           alt="arrow"
                           height="100%"
                           style={{ marginLeft: '1vw' }}
                         />
                       </div>
                     </div>
                   </Button>
                 ) : (
                   <></>
                 )}
               </div>
             </div>
             <DialogOverlay show={showDialog}>
               <DialogBox>
                 <div style={{ fontSize: '1.4vw', marginBottom: '3vh' }}>
                   Service Included
                 </div>
                 {Array.isArray(coupons) && coupons.length > 0 ? (
                   <CouponList>
                     {coupons.map((coupon, index) => (
                       <CouponItem key={index}>
                         <img   src={`https://www.dsctech.in${
                           coupon.image
                         }`}  style={{width:'8vw', borderRadius:'1.5vw', marginRight:'1vw', height:'8vw'}}/>
                       <div>
                       <div  style={{fontSize:'1.2vw', }}>{coupon.name}</div>
                        <div style={{fontSize:'1.2vw', opacity:'70%', marginTop:'0.5vh' }}>
                        {coupon.description}
                        </div>
                       </div>
                       </CouponItem>
                     ))}
                   </CouponList>
                 ) : (
                   <div>No coupons available</div> // Optionally show a message if there are no coupons
                 )}
                 <div
                   style={{
                     position: 'sticky',
                     bottom: 0,
                     backgroundColor: 'white',
                     padding: '2vh',
                     textAlign: 'center',
                     borderTop: '1px solid #ccc',
                   }}
                 >
                   <ApplyButton onClick={() => setShowDialog(false)}>
                     Close
                   </ApplyButton>
                 </div>
               </DialogBox>
             </DialogOverlay>
 
             <GridContainer>
               {shops.map((shop, index) => (
                 <GridItem
                   key={shop.pk}
                   onClick={() => handleClick(shop.pk, shop.fields?.distance, shop.fields.car_services.calculated_price)}
                 >
                   <div style={{ display: 'flex' }}>
                     <div
                       style={{
                         height: '21vh',
                         width: '21vh',
                         borderRadius: '2vw',
                         backgroundColor: 'transparent',
                         overflow: 'hidden',
                         margin: '3vh',
                         display: 'flex',
                         alignItems: 'center',
                         justifyContent: 'center',
                       }}
                     >
                       <img
                         src={`https://www.dsctech.in${
                           shop.fields?.upload_carshop_image || ''
                         }`}
                         alt="Car Shop"
                         style={{
                           height: '100%',
                           width: '100%',
                           borderRadius: '2vw',
                         }}
                       />
                     </div>
                     <div
                       style={{
                         display: 'flex',
                         flexDirection: 'column',
                         justifyContent: 'start',
                         marginTop: 'auto',
                         marginBottom: 'auto',
                         marginLeft: '1vw',
                       }}
                     >
                       <div style={{ fontSize: '1.5vw' }}>
                         {shop.fields?.shop_name || 'Shop'}
                       </div>
                       <div style={{ color: 'rgb(0, 0, 0, 0.6)' }}>
                         {shop.fields?.distance} kms away
                       </div>
                       <div style={{ color: 'rgb(0, 0, 0, 0.6)' }}>
                         {shop.fields?.address || 'No address available'}
                       </div>
                     </div>
                     <div
                       style={{
                         marginLeft: 'auto',
                         marginTop: 'auto',
                         marginBottom: 'auto',
                         marginRight: '3vh',
                       }}
                     >
                       <div
                         style={{
                           height: '7vh',
                           width: '7vh',
                           borderRadius: '50%',
                           backgroundColor: '#FFE9C2',
                           alignItems: 'center',
                           justifyContent: 'center',
                           display: 'flex',
                           border: '1px solid #faba49',
                         }}
                       >
                         <img
                           src={arrow}
                           alt="arrow"
                           height="40%"
                           style={{ marginLeft: '0.5vh' }}
                         />
                       </div>
                     </div>
                   </div>
                   <div
                     style={{
                       marginLeft: '3vh',
                       marginRight: '3vh',
                       marginBottom: '3vh',
                       display: 'flex',
                       justifyContent: 'space-between',
                     }}
                   >
                     {ratings &&
                       ratings.ratings &&
                       ratings.ratings[0]?.carservice?.duration_minutes && (
                         <div
                           style={{
                             height: '6vh',
                             width: '18vw',
                             fontSize: '1.1vw',
                             backgroundColor: '#FFE9C2',
                             borderRadius: '2vw',
                             alignItems: 'center',
                             justifyContent: 'center',
                             display: 'flex',
                             border: '1px solid #faba49',
                           }}
                         >
                           Washtime -{' '}
                           {ratings.ratings[0].carservice.duration_minutes}min
                         </div>
                       )}
 
                     <div
                       style={{
                         height: '6vh',
                         width: '18vw',
                         fontSize: '1.1vw',
                         backgroundColor: '#FFE9C2',
                         borderRadius: '2vw',
                         alignItems: 'center',
                         justifyContent: 'center',
                         display: 'flex',
                         border: '1px solid #faba49',
                       }}
                     >
                       ₹ {shop.fields.car_services.calculated_price}
                     </div>
                   </div>
                 </GridItem>
               ))}
             </GridContainer>
           </div>
         ) : (
           <div
             style={{
               width: '100%',
               marginTop: '6vh',
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               justifyContent: 'center',
             }}
           >
             <div>
               We're sorry, but there are no services in your location right now.
               We're constantly expanding, so please stay tuned!
             </div>
             <img src={atlocation} height="200" style={{ marginTop: '3vh' }} />
           </div>
         )}
       </InnerContainer>
      )}
    </Container>
  )
}

export default Shop
